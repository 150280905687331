<h1>{{ "navigation.farmMapping" | translate }}</h1>
<p>{{ "info.farmMappingAdmin" | translate }}</p>
<button class="green" id="addKBO" (click)="addKBO()">
  <i class="fas fa-plus"></i>
  {{ "models.farmMapping.addKBO" | translate }}
</button>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="farmMappingService"
  [exportable]="true"
  queryOnLangChange="true"
  [lastColumnIcon]="true"
>
</app-base-remote-datatable>

<ng-template #kboCell let-kbo="kbo">
  <ng-template [ngIf]="!kbo">
    <p>{{ "models.farmAccess.statuses.NotFound" | translate }}</p>
  </ng-template>
  <ng-template [ngIf]="kbo">
    <a
      href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer={{
        kbo
      }}"
      target="_blank"
      >{{ kbo }}</a
    >
  </ng-template>
</ng-template>

<ng-template #farmIdCell let-farmId="farmId" let-kbo="kbo">
  <button
    class="details-button"
    (click)="showFarmDetailsForId(farmId, kbo ? kbo : null)"
    style="padding: 0.25rem !important"
  >
    {{ farmId }}
  </button>
</ng-template>

<ng-template #farmIdTypeIdCell let-farmIdTypeId="farmIdTypeId">
  {{ farmIdTypeId | FarmTypeTranslation | translate }}
</ng-template>

<ng-template #numberCell let-number="number">
  {{ number }}
</ng-template>

<ng-template #editTmpl let-farmId="farmId">
  <button
    aria-label="edit this farmmapping"
    (click)="editFarmmapping(farmId)"
    style="padding: 0.5rem 0.25rem !important"
    class="card-button editFarmmapping"
  >
    <i class="fa fa-edit"></i>
  </button>
</ng-template>
<ng-template [ngIf]="farmDetailsArray.length > 0">
  <button class="rose" (click)="hideAllDetails()" id="hideAllDetails">
    {{ "models.farmMapping.hideAll" | translate }}
  </button>
</ng-template>

<div class="horizontal">
  <div
    class="farm-details"
    [ngStyle]="{ background: getColor(indexOfElement) }"
    *ngFor="let d of farmDetailsArray; let indexOfElement = index"
  >
    <ng-template [ngIf]="d.hidden === false || d.hidden === undefined">
      <div class="header">
        <h2>Farm Id:</h2>
        <p class="bold">
          {{ d.farmId ? d.farmId : "--" }}
        </p>
        <button
          (click)="hideFarmDetailsForId(d.farmId)"
          class="close-farm-details"
          style="padding: 0.5rem !important"
        >
          &#x274c;
        </button>
      </div>
      <form class="item">
        <label [for]="'farmmapping-numbers-' + d.farmId">
          <h3>
            {{ "models.farmMapping.numbers" | translate }}
          </h3>
        </label>
        <input
          type="checkbox"
          [name]="'farmmapping-numbers-' + d.farmId"
          [id]="'farmmapping-numbers-' + d.farmId"
        />
        <div class="expandable">
          <!-- <ul>
                <li *ngFor="let mapping of d.mappings">
                  <p class="bold">
                    {{ mapping?.farmIdTypeId | FarmTypeTranslation | translate}} :
                  </p>
                  <p>{{ mapping?.number ? mapping.number : "--" }}</p>
                </li>
              </ul> -->
          <ng-container *ngFor="let mapping of d.mappings; let i = index">
            <ng-container
              *ngIf="
                !i || d.mappings[i - 1].farmIdTypeId !== mapping.farmIdTypeId
              "
            >
              <p>
                {{ mapping?.farmIdTypeId | FarmTypeTranslation | translate }}
                :
              </p>
              <ul>
                <li>
                  <p>{{ mapping?.number ? mapping.number : "--" }}</p>
                </li>
                <ng-container *ngFor="let m of d.mappings.slice(i + 1)">
                  <ng-container *ngIf="m.farmIdTypeId === mapping.farmIdTypeId">
                    <li>
                      <p>{{ m?.number ? m.number : "--" }}</p>
                    </li>
                  </ng-container>
                </ng-container>
              </ul>
            </ng-container>
          </ng-container>
        </div>

        <label [for]="'farmmapping-users-' + d.farmId">
          <h3>
            {{ "models.farmMapping.users" | translate }}
          </h3>
        </label>
        <input
          type="checkbox"
          [name]="'farmmapping-users-' + d.farmId"
          [id]="'farmmapping-users-' + d.farmId"
        />
        <div class="expandable">
          <ul>
            <li *ngIf="d.users.length === 0">
              {{ "models.farmMapping.noUsers" | translate }}
            </li>
            <li *ngFor="let user of d.users">
              <p class="bold">
                {{ "models.farmMapping.email" | translate }}
              </p>
              <p>{{ user?.email }}</p>
              <p class="bold">
                {{ "models.farmMapping.notificationStatus" | translate }}
              </p>
              <p [innerHtml]="user?.status | BooleanCheckmarkTranslation"></p>
            </li>
          </ul>
        </div>
      </form>
      <div class="item">
        <mat-card-footer> </mat-card-footer>
      </div>
    </ng-template>
  </div>
</div>
