<h1>{{ "navigation.farmDetails" | translate }}</h1>
<p class="information-part">{{ "info.farmDetails" | translate }}</p>
<p class="information-part">{{ "farmDetails.text" | translate }}</p>
<p class="information-part" [innerHTML]="'farmDetails.text2' | translate"></p>
<app-relog-link></app-relog-link>
<div class="farm-details">
  <div
    [ngStyle]="{ order: order[record.type] }"
    class="farm-details-content"
    *ngFor="let record of (details$ | async)?.mappings"
  >
    <p class="bold">{{ record.type | FarmTypeTranslation | translate }}</p>
    <div class="item-list">
      <ng-container
        *ngTemplateOutlet="
          record.values.length <= 5 ? normalList : scrollList;
          context: { values: record.values }
        "
      >
      </ng-container>
    </div>
  </div>
</div>
<button class="blue" type="button" (click)="openReportDialog()">
  {{ "actions.reportError" | translate }}
</button>
<ng-template #normalList let-values="values">
  <p class="list-item" *ngFor="let value of values">
    {{ value }}
  </p>
</ng-template>
<ng-template #scrollList let-values="values">
  <cdk-virtual-scroll-viewport class="viewport" itemSize="1">
    <ng-container *cdkVirtualFor="let value of values">
      <p class="viewport-item">
        {{ value }}
      </p>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>
