<h1 mat-dialog-title>{{ "manageApisPage.newApiDialog.title" | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="onCreate(form.getRawValue())">
  <mat-dialog-content class="dialog-content">
    <mat-form-field>
      <app-translatable-string
        formControlName="apiName"
        placeholder="{{
          'manageApisPage.newApiDialog.apiName.placeholder' | translate
        }}"
        [required]="true"
        [disabled]="false"
        cdkFocusInitial
      >
      </app-translatable-string>
      <mat-hint>{{
        "manageApisPage.newApiDialog.apiName.description" | translate
      }}</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="apiUrl"
        placeholder="{{
          'manageApisPage.newApiDialog.apiUrl.placeholder' | translate
        }}"
        required
      />
      <mat-hint>{{
        "manageApisPage.newApiDialog.apiUrl.description" | translate
      }}</mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="openApiSpecUrl"
        placeholder="{{
          'manageApisPage.newApiDialog.openApiSpecUrl.placeholder' | translate
        }}"
      />
      <mat-hint
        >{{
          "manageApisPage.newApiDialog.openApiSpecUrl.description" | translate
        }}
      </mat-hint>
    </mat-form-field>
    <mat-form-field>
      <input
        matInput
        formControlName="farmIdUrl"
        placeholder="{{
          'manageApisPage.newApiDialog.farmIdUrl.placeholder' | translate
        }}"
      />
      <mat-hint>{{
        "manageApisPage.newApiDialog.farmIdUrl.description" | translate
      }}</mat-hint>
    </mat-form-field>
  </mat-dialog-content>
  <div mat-dialog-actions>
    <button type="button" class="dialog-button" (click)="onCancel()">{{ "actions.cancel" | translate }}</button>
    <button type="submit" [disabled]="!form.valid" class="green dialog-button">
      {{ "actions.createNewApi" | translate }}
    </button>
  </div>
</form>
