import { Component, Input, OnInit, Inject} from "@angular/core";
import { UserAuthService } from "../../../core/user-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { UserService } from "../../../core/services/user.service";
import { MsalService } from "@azure/msal-angular";
import { NotificationDialogComponent } from "../../../shared/dialog/notification-dialog/notification-dialog.component";
import { baseurl } from "../../../../baseSettings";
import { HttpClient, HttpXhrBackend } from "@angular/common/http";

@Component({
  selector: "app-no-role-start-page",
  templateUrl: "./no-role-start-page.component.html",
  styleUrls: ["./no-role-start-page.component.css"],
})
export class NoRoleStartPageComponent implements OnInit {
  
  @Input() 
  userId: string;
  @Input() 
  validId: boolean = false;
  @Input() 
  identityId: string;
  @Input()
  firstName: string;
  @Input()
  lastName: string;
  @Input()
  emailAddress: string;
  
  httpClient = new HttpClient(
    new HttpXhrBackend({
      build: () => new XMLHttpRequest(),
    })
  );
  private URL = `${this.baseUrl}/api/user`;

  constructor(
    private authService: UserAuthService,
    private dialog: MatDialog,
    private userService: UserService,
    private msalService: MsalService,
    @Inject(baseurl) private baseUrl: string
  ) {}

  ngOnInit() {
  }

  loginAsLegalRepresentative(){
    this.authService.relog();
  }

  reuseEmail(){
    this.deleteUser(() => this.signUpAgain());
  }

  signUpAgain(){
    //send a register email with old emailadress and log out
    this.httpClient
    .post(`${this.URL}/farmer-sign-up`, {
      email: this.emailAddress,
    })
    .subscribe(() => {
      this.msalService.logout();
    });
  }

  gotoRegisterPage(){
    const session = JSON.parse(sessionStorage.getItem("appConfig"));
    const postLogoutRedirectUri = session["msal"]["base"]["auth"] ? session["msal"]["base"]["auth"]["postLogoutRedirectUri"] : "/";
    this.msalService.logoutRedirect({ postLogoutRedirectUri: `${postLogoutRedirectUri}/farmer-signup`});
  }

  deleteUserAccountConfirmation() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      height: "200px",
      width: "400px",
      data: {
        title: "viewUserPage.deleteUserAccount.title",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteUser(() => this.msalService.logout());
      }
    });
  }

  deleteUser(callback: () => void) {
      this.userService.deleteOwnUserAccount(this.userId).subscribe((response) => {
        const isFinalUserOfAnyContext = response;
        if (isFinalUserOfAnyContext == false) {
          callback();
        } else {
          const dialogRef = this.dialog.open(NotificationDialogComponent, {
            height: "400px",
            width: "400px",
            data: {
              title: "viewUserPage.deleteUserAccount.finalUserTitle",
              text: "viewUserPage.deleteUserAccount.finalUserText",
            },
          });
        }
      });
  }
}
