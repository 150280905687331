import { UserRole } from './datahubUser';

export class UserContextDTO {
    id: string;
    roleName: UserRole;
    userId: string;
    entityName: string;
    entityId: string;

    get isAdmin() {
        return this.roleName && this.roleName === UserRole.Admin;
    }
}
