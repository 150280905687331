<section>
  <h1 class="d-inline-flex">
    {{ "manageConsumerAccessPage.pageTitle" | translate }}
  </h1>
  <p>{{ "manageConsumerAccessPage.description" | translate }}</p>
</section>
<section>
  <form novalidate>
    <div class="form-part">
      <ng-template *ngIf="consumerAccess.accessUntil; else noEndDate">
        <label>{{ "manageConsumerAccessPage.endDate" | translate }}</label>
        <input
          name="accessUntil"
          type="date"
          matInput
          [readonly]="true"
          [min]="notBefore"
          [(ngModel)]="consumerAccess.accessUntil"
        />
      </ng-template>
      <ng-template #noEndDate>
        <p class="bold">{{ "manageConsumerAccessPage.endDate" | translate }}</p>
        <p>{{ "manageConsumerAccessPage.noEndDate" | translate }}</p>
      </ng-template>
    </div>
    <p class="bold">{{ "manageConsumerAccessPage.resources" | translate }}</p>
    <ng-select
      name="resources"
      [items]="translatedResources"
      [multiple]="true"
      bindLabel="displayName"
      bindValue="id"
      [(ngModel)]="consumerAccess.resourcesIds"
    >
      <ng-template ng-label-tmp let-item="item" let-clear="clear">
        <span class="ng-value-label">{{ item.displayName }}&nbsp;</span>
        <span
          class="ng-value-icon right"
          (click)="clear(item)"
          aria-hidden="true"
          >×</span
        >
      </ng-template>

      <ng-template ng-option-tmp let-item="item">
        {{ item.displayName }}
      </ng-template>
    </ng-select>

    <p class="bold">{{ "manageConsumerAccessPage.farms" | translate }}</p>

    <div class="form-part">
      <!-- <div class="col col-md-6">
          <mat-checkbox 
            name="allFarmsCheck"
            [(ngModel)]="consumerAccess.areAllFarmsSelected"
            [disabled]="!canCheckAllFarms()"
            (change)="onAllFarmsClick()"
          >
            {{ "manageConsumerAccessPage.allFarms" | translate }}
          </mat-checkbox>
        </div> -->
      <label for="farmIdType" aria-label="select a farm id type">
        <select
          name="farmIdType"
          [(ngModel)]="consumerAccess.farmIdType"
          [compareWith]="compareFarmIdType"
          [disabled]="!canSpecifyFarms()"
        >
          <option *ngFor="let farmId of farmIdTypes" [ngValue]="farmId">
            {{ farmId.name }}
          </option>
        </select>
      </label>
    </div>

    <app-consumer-manage-farmids
      [ca]="consumerAccess"
      [unsavedChanges]="areFarmIdsDirty()"
      [disabled]="!canSpecifyFarms()"
    >
    </app-consumer-manage-farmids>

    <button
      [disabled]="!canSubmit()"
      type="submit"
      (click)="onSave()"
      class="green"
      id="submitRequest"
    >
      {{ "actions.submitRequest" | translate }}
    </button>
  </form>
</section>
