<h1>{{ "info.manageUnclaimedInvites" | translate }}</h1>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="manageUnclaimedInvitesService"
  queryOnLangChange="true"
>
</app-base-remote-datatable>

<ng-template #partnerNameCell let-partnerName="partnerName">
  {{ partnerName }}
</ng-template>

<ng-template #createdAtCell let-createdAt="createdAt">
  {{ createdAt | FromUtcToLocalDate | date : "dd/MM/yyyy HH:mm:ss" }}
</ng-template>

<ng-template #userRoleCell let-userRole="userRole">
  {{ "roles." + getRole(userRole) | translate }}
</ng-template>

<ng-template #isSignUpCell let-isSignUp="isSignUp">
  {{ isSignUp | BooleanTranslation | translate }}
</ng-template>

<ng-template #kboCell let-kbo="kbo">
  {{ kbo }}
</ng-template>

<ng-template #emailCell class="emailCell" let-email="email">
  {{ email }}
</ng-template>

<ng-template #inviteIdCell let-inviteId="inviteId" let-email="email">
  <div class="float-right">
    <button
      (click)="resendInvite(inviteId, email)"
      class="card-button resend-invite"
      aria-label="Resend invite"
    >
      <i class="fa fa-envelope"></i>
    </button>
  </div>
</ng-template>

<ng-template #copyInviteIdCell let-inviteId="inviteId" let-email="email">
  <div class="float-right">
    <button
      (click)="copyInviteLink(inviteId, email)"
      class="card-button copy-invite"
      aria-label="Copy invite"
    >
      <i class="fa fa-copy"></i>
    </button>
  </div>
</ng-template>
