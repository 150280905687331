import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TermsSummaryDTO, TermsUpdateDTO } from '../../models/termsSummaryDTO';

@Component({
  selector: 'app-terms-dialog',
  templateUrl: './terms-dialog.component.html',
  styleUrls: ['./terms-dialog.component.css']
})
export class TermsDialogComponent implements OnInit {

  approvePrivacyPolicy: boolean;
  allowDirectMarketing: boolean;
  approveFarmContract: boolean;
  approvePartnerContract: boolean;
  termsUpdate: TermsUpdateDTO;
 
  constructor(public dialogReg: MatDialogRef<TermsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { termsSummaryDTO: TermsSummaryDTO, isFarmer: boolean, isPartner: boolean, isAdmin: boolean }

  ) { }

  ngOnInit() {
    
    //check if there is an approved privacy policy
    this.termsUpdate = new TermsUpdateDTO();
    if (this.data.termsSummaryDTO.signedPrivacyPolicyVersion != null && this.data.termsSummaryDTO.signedPrivacyPolicyVersion != "") 
    {      
      if(this.data.termsSummaryDTO.isNewestPrivacyPolicyVersion){
        this.approvePrivacyPolicy = true;
      }
    }
    
    this.allowDirectMarketing = this.data.termsSummaryDTO.allowDirectMarketing;
    if (this.data.isFarmer && this.data.termsSummaryDTO.signedFarmContractVersion != null && this.data.termsSummaryDTO.signedFarmContractVersion != "") {
      if(this.data.termsSummaryDTO.isNewestFarmContractVersion){
        this.approveFarmContract = true
      }
    }
    if(this.data.isPartner && this.data.termsSummaryDTO.signedPartnerContractVersion != null && this.data.termsSummaryDTO.signedPartnerContractVersion != ""){
      if(this.data.termsSummaryDTO.isNewestPartnerContractVersion){
        this.approvePartnerContract = true
      }
    }
  }

  onAccept() {
    if (this.allowDirectMarketing == null) {
      this.allowDirectMarketing = false;
    }
    this.termsUpdate.allowDirectMarketing = this.allowDirectMarketing;
    this.termsUpdate.signedPrivacyPolicy = this.approvePrivacyPolicy;
    this.termsUpdate.signedFarmContract = this.approveFarmContract || null;
    this.termsUpdate.signedPartnerContract = this.approvePartnerContract || null;
    this.termsUpdate.userId = this.data.termsSummaryDTO.userId || null;
    this.termsUpdate.farmId = this.data.termsSummaryDTO.farmId || null;
    this.termsUpdate.partnerId = this.data.termsSummaryDTO.partnerId || null;
    this.dialogReg.close(this.termsUpdate);
  }

  onLeaveOpen(){
    this.dialogReg.close();
  }

}
