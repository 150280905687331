import { Component, Input, OnInit, SimpleChanges } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { Subscription } from "rxjs";
import { ConsumerAccess } from "../../models/consumer-access";
import { AddFarmidsComponent } from "./add-farmids/add-farmids.component";
import { NotificationDialogComponent } from "../../../shared/dialog/notification-dialog/notification-dialog.component";

@Component({
  selector: "app-consumer-manage-farmids",
  templateUrl: "./consumer-manage-farmids.component.html",
  styleUrls: ["./consumer-manage-farmids.component.scss"],
})
export class ConsumerManageFarmidsComponent implements OnInit {
  @Input() ca: ConsumerAccess;
  @Input() disabled: boolean;
  @Input() unsavedChanges: boolean;

  farmIdsBuffer = new Array<string>();
  subscription = new Subscription();
  filterValue = "";

  constructor(private dialog: MatDialog) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ca) {
      this.ca = changes.ca.currentValue;
      this.setBuffer();
    }
  }

  add() {
    const dialogRef = this.dialog.open(AddFarmidsComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((farmIds) => {
        if (farmIds) {
          let duplicates: string[] = [];
          if (!this.ca.farmsIds) {
            this.ca.farmsIds = farmIds;
          } else {
            const uniqueFarmIds = farmIds.filter((farmId) => {
              if (this.ca.farmsIds.includes(farmId)) {
                duplicates.push(farmId);
                return false;
              }
              return true;
            });
            this.ca.farmsIds = this.ca.farmsIds.concat(uniqueFarmIds);
          }
          this.setBuffer();

          if (duplicates.length > 0) {
            this.dialog.open(NotificationDialogComponent, {
              data: {
                title: "manageConsumerAccessPage.duplicateDialog.title",
                text: "manageConsumerAccessPage.duplicateDialog.text",
                textParams: { duplicates: duplicates.join(", ") },
              },
              width: "500px",
            });
          }
        }
      })
    );
  }

  filter() {
    this.setBuffer();
  }

  remove(farmId: string) {
    if (!this.disabled) {
      this.ca.farmsIds = this.ca.farmsIds.filter((f) => f !== farmId);
      this.setBuffer();
    }
  }

  clearAll() {
    this.ca.farmsIds = new Array<string>();
    this.setBuffer();
  }

  private setBuffer() {
    if (this.ca.farmsIds)
      this.farmIdsBuffer = this.ca.farmsIds.filter((f) =>
        f.includes(this.filterValue)
      );
  }
}
