import { Component, Input, SimpleChanges } from "@angular/core";
import {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
} from "chart.js";
import { Chart } from "chart.js/auto";

@Component({
  selector: "app-chartjs-barchart",
  templateUrl: "./chartjs-barchart.component.html",
  styleUrl: "./chartjs-barchart.component.scss",
})
export class ChartjsBarchartComponent {
  @Input() data: ChartData;
  @Input() options: ChartOptions;
  @Input() title: string;
  private type: ChartType = "line";
  private config: ChartConfiguration = {
    type: this.type,
    data: null,
    options: null,
  };
  public chart: Chart;

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.initializeChart();
  }

  private initializeChart(): void {
    if (!this.data) {
      throw new Error("Input 'data' is required for ChartjsBarchartComponent");
    }
    if (!this.options) {
      throw new Error(
        "Input 'options' is required for ChartjsBarchartComponent"
      );
    }
    if (!this.title) {
      throw new Error("Input 'title' is required for ChartjsBarchartComponent");
    }

    this.config.data = this.data;
    this.config.options = this.options;

    setTimeout(() => {
      this.createChart();
    });
  }

  private createChart(): void {
    this.destroyChart();
    const ctx = document.getElementById(this.title) as HTMLCanvasElement;
    if (ctx) {
      this.chart = new Chart(ctx, this.config);
      this.chart.update();
      return;
    }
    console.error(`Element with id '${this.title}' not found`);
  }

  private destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }
}
