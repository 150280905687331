<iframe
  src="https://sailvodatahubprod01.blob.core.windows.net/cookie-check/cookie.html"
  style="display: none"
></iframe>

<app-header
  *ngIf="!(disableApp | async)"
  [context]="context"
  [isLoggedIn]="isLoggedIn"
  [hasMultipleContexts]="hasMultipleContexts"
  [isLoggedInAndHasRole]="isLoggedInAndHasRole"
></app-header>

<main [ngClass]="{ loggedIn: isLoggedInAndHasRole }">
  <ng-template [ngIf]="isLoggedInAndHasRole">
    <app-breadcrumb></app-breadcrumb>
  </ng-template>
  <div *ngIf="disableApp | async" class="text-center mt-30">
    <h2>{{ "cookies.thirdParty.disabled.title" | translate }}</h2>
    <p [innerHtml]="'cookies.thirdParty.disabled.text' | translate"></p>
  </div>
  <ng-template [ngIf]="!(disableApp | async)">
    <ng-template [ngIf]="isLoggedInAndHasRole && hasCookie()">
      <app-nav-menu></app-nav-menu>
    </ng-template>
    <div
      [ngClass]="{
        'avoid-clicks': !hasCookie(),
        'body-content': isLoggedInAndHasRole,
        'body-content-no-login': !isLoggedInAndHasRole
      }"
    >
      <router-outlet></router-outlet>
    </div>
  </ng-template>
  <app-cookie-banner *ngIf="!hasCookie()"></app-cookie-banner>
</main>

<app-footer [isDevEnv]="isDevEnv" [version]="version"></app-footer>

<ngx-spinner
  bdColor="rgba(51,51,51,0.7)"
  size="medium"
  color="#fff"
  type="ball-circus"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
