<div class="header-page">
  <h1>
    {{ "manageApisPage.details.pageTitle" | translate }}
  </h1>
  <p>{{ "info.apiDetails" | translate }}</p>
</div>
<form>
  <section class="information">
    <div class="information-part">
      <p class="bold">
        {{ "manageApisPage.newApiDialog.apiName.placeholder" | translate }}:
      </p>

      <app-translatable-string-view
        [translation]="api.translations"
        property="apiName"
      >
      </app-translatable-string-view>
    </div>
    <div class="information-part">
      <p class="bold">
        {{ "manageApisPage.newApiDialog.apiUrl.placeholder" | translate }}:
      </p>
      <p>{{ this.api.apiUrl }}</p>
    </div>
    <div class="information-part">
      <p class="bold">
        {{
          "manageApisPage.newApiDialog.openApiSpecUrl.placeholder" | translate
        }}:
      </p>
      <p>{{ this.api.openApiSpecUrl }}</p>
    </div>
    <div class="information-part">
      <p class="bold">
        {{ "manageApisPage.newApiDialog.farmIdUrl.placeholder" | translate }}:
      </p>
      <p>{{ this.api.farmIdUrl }}</p>
    </div>
  </section>

  <section class="information">
    <app-credentials [api]="this.api" [showLabel]="true"> </app-credentials>

    <div class="information-part vertical">
      <label>{{ "manageApisPage.details.tags" | translate }}</label>
      <ng-select
        name="tags"
        [disabled]="!this.api.isEditable()"
        [items]="allTags$ | async"
        [multiple]="true"
        bindLabel="name"
        [(ngModel)]="api.tags"
        [addTag]="addTag"
      >
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">{{ item.name }}&nbsp;</span>
          <span
            class="ng-value-icon right"
            (click)="clear(item)"
            aria-hidden="true"
            >×</span
          >
        </ng-template>

        <ng-template ng-option-tmp let-item="item">
          {{ item.name }}
        </ng-template>
      </ng-select>
    </div>
  </section>
  <app-manage-headers [isEditable]="this.api.isEditable()" [object]="this.api">
  </app-manage-headers>

  <section class="information">
    <h2>{{ "manageApisPage.details.resources" | translate }}</h2>
    <app-resource
      *ngFor="let resource of api.resources"
      [item]="resource"
      [isEditable]="this.api.isEditable()"
      [validationResult]="getValidationResult(resource.id)"
    >
    </app-resource>
  </section>

  <div class="button-group">
    <button type="reset" (click)="back()" class="green">
      {{ "actions.back" | translate }}
    </button>
    <button
      *ngIf="this.api.isPublished()"
      type="button"
      (click)="onUpdate()"
      class="green"
    >
      {{ "actions.update" | translate }}
    </button>
    <ng-template *ngIf="this.api.isEditable()">
      <button
        type="button"
        [disabled]="!this.api.isDeletable()"
        (click)="onDelete()"
        class="rose"
      >
        {{ "actions.delete" | translate }}
      </button>
    </ng-template>
    <button
      *ngIf="isAdmin && this.api.canBePublished()"
      type="button"
      (click)="reject()"
      class="rose"
    >
      {{ "actions.reject" | translate }}
    </button>
    <button
      *ngIf="this.api.isEditable()"
      type="button"
      (click)="onSave()"
      class="green"
    >
      {{ "actions.saveAsDraft" | translate }}
    </button>
    <button
      *ngIf="isAdmin && this.api.canBePublished()"
      type="button"
      (click)="publish()"
      class="green"
    >
      {{ "actions.publish" | translate }}
    </button>
  </div>
  <div class="button-group">
    <button
      *ngIf="this.api.isEditable()"
      type="button"
      (click)="validate()"
      class="green"
    >
      {{ "actions.validate" | translate }}
    </button>
    <button
      *ngIf="this.api.isEditable()"
      class="pull-right"
      type="button"
      (click)="submitForApproval()"
      class="green"
    >
      {{ "actions.submit" | translate }}
    </button>
  </div>
</form>
