import {
  Component,
  OnInit,
  Input,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { merge, Subject, Subscription } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { RemoteDataSource } from "./remote-data-source";
import { IPagedSortedFilteredService } from "../../core/services/ipaged-sorted-filtered.service";
import { InitialSort } from "./models/initial-sort";
import { ColumnDef } from "./models/column-def";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { exportDataToCsv } from "../models/export-to-csv";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { Observable } from "rxjs-compat";
import { Page } from "../models/page";
import { SpinnerService } from "../../core/services/spinner.service";

@Component({
  selector: "app-base-remote-datatable",
  templateUrl: "./base-remote-datatable.component.html",
  styleUrls: ["./base-remote-datatable.component.scss"],
})
export class BaseRemoteDatatableComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() lastColumnIcon: boolean = false;
  @Input() firstColumnIcon: boolean = false;
  @Input() columns: Array<ColumnDef>;
  @Input() initialSort: InitialSort = InitialSort.None;
  @Input() service: IPagedSortedFilteredService<any>;
  @Input() queryOnLangChange: boolean = false;
  private _exportable = true;
  @Input() get exportable() {
    return this._exportable;
  }
  set exportable(value) {
    this._exportable = value != null ? value : true;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  displayedColumns: Array<any>;
  dataSource: RemoteDataSource<any>;
  filterChanges$ = new Subject<any[]>();
  private filter = {};
  private subscriptions = new Subscription();

  constructor(
    private translate: TranslateService, 
    private dialog: MatDialog,
    private spinnerService: SpinnerService
  ) {
    this.subscriptions.add(
      this.translate.onLangChange.subscribe((l: LangChangeEvent) => {
        setTimeout(() => {
          // This is a hack to make sure the paginator is updated after the language change
          this.loadPage();
        }, 50);
      })
    );
  }

  ngOnInit() {
    if (this.queryOnLangChange) {
      this.subscriptions.add(
        this.translate.onLangChange.subscribe((l) => this.loadPage())
      );
    }

    this.displayedColumns = this.columns
      .filter((c) => c.displayed)
      .map((c) => c.name);
    this.setDataSource();

    this.subscriptions.add(
      this.filterChanges$
        .pipe(debounceTime(500), distinctUntilChanged())
        .subscribe((filterValues) => {
          this.filter = { ...this.filter, ...filterValues };
          this.loadPage();
        })
    );
  }

  setDataSource() {
    this.dataSource = new RemoteDataSource<any>(this.service);
    this.dataSource.loadData(1, 10, this.initialSort.getSortString()).subscribe();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  ngAfterViewInit() {
    this.subscriptions.add(
      this.filterChanges$
        .pipe(
          debounceTime(400),
          distinctUntilChanged(),
          switchMap((akvp, index) => {
            akvp.forEach((kvp) => {
              if (kvp.value && kvp.value !== "" && kvp.value !== -1) {
                this.filter[kvp.key] = kvp.value;
              } else {
                delete this.filter[kvp.key];
              }
            });
            this.paginator.pageIndex = 0;
            return this.loadPage();
          })
        )
        .subscribe()
    );

    this.subscriptions.add(
      this.sort.sortChange.subscribe(() => (this.paginator.pageIndex = 0))
    );

    this.subscriptions.add(
      merge(this.sort.sortChange, this.paginator.page)
        .pipe(
          switchMap(() => {
            return this.loadPage()
          })
        )
        .subscribe()
    );
  }

  loadPage(): Observable<Page<any>> {
    return this.dataSource.loadData(
      this.paginator.pageIndex + 1,
      this.paginator.pageSize,
      `${this.sort.active}-${this.sort.direction}`,
      this.filter
    );
  }

  // Changed version of https://stackoverflow.com/questions/51487689/angular-5-how-to-export-data-to-csv-file
  async exportToCsv(): Promise<any> {
    if (!this.exportable) {
      return;
    }

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: {
        title: "actions.warning",
      },
    });
    dialogRef.afterClosed().subscribe(async (result) => {
      // User clicked no
      if (!result) return;

      //blocking spinner prevents flickering of the loading screen on multiple get requests
      this.spinnerService.showSpinnerAndBlock();
      const date = new Date();
      const filename = `${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}.csv`;
      const pageSize = 150;
      const keys = this.columns
        .filter(
          (c) => c.name && !(c.name as string).includes("actions.actions")
        )
        .map((c) => c.name);
      let pageNumber = 1;
      let resultArray = new Array<string>();
      let response = await this.service
        .get(
          pageNumber++,
          pageSize,
          this.initialSort.getSortString(),
          this.filter
        )
        .toPromise();
      if (response) {
        let csvContent = exportDataToCsv(keys, response.items);
        resultArray = resultArray.concat(csvContent);
        const maxPages =
          Math.ceil(response.totalCount / 150) > 50
            ? 50
            : Math.ceil(response.totalCount / pageSize);
        while (response && pageNumber <= maxPages) {
          response = await this.service
            .get(
              pageNumber++,
              pageSize,
              this.initialSort.getSortString(),
              this.filter
            )
            .toPromise();
          if (response) {
            csvContent = exportDataToCsv(
              keys,
              response.items,
              undefined,
              false
            );
            resultArray = resultArray.concat(csvContent);
          }
        }
      }
      this.spinnerService.unblockSpinner();
      const blob = new Blob([resultArray.join("\r\n")], {
        type: "text/csv;charset=utf-8;",
      });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          // Browsers that support HTML5 download attribute
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  }
}
