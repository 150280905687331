import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { AllUsersService } from "../../core/services/all-users.service";
import { UserService } from "../../core/services/user.service";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { SelectFilterDef } from "./../../shared/base-remote-datatable/models/select-filter-def";
import { TextFilterDef } from "./../../shared/base-remote-datatable/models/text-filter-def";
import {
  DatahubUserDetails,
  DatahubUserStatus,
  UserRole,
} from "./../../shared/models/datahubUser";

@Component({
  selector: "app-admin-manage-users",
  templateUrl: "./admin-manage-users.component.html",
  styleUrls: ["./admin-manage-users.component.scss"],
})
export class AdminManageUsersComponent implements OnInit {
  @ViewChild("idCell", { static: true }) idCell: TemplateRef<any>;
  @ViewChild("firstNameCell", { static: true }) firstNameCell: TemplateRef<any>;
  @ViewChild("lastNameCell", { static: true }) lastNameCell: TemplateRef<any>;
  @ViewChild("emailCell", { static: true }) emailCell: TemplateRef<any>;
  @ViewChild("statusCell", { static: true }) statusCell: TemplateRef<any>;
  @ViewChild("rolesCell", { static: true }) rolesCell: TemplateRef<any>;
  @ViewChild("createdAtCell", { static: true }) createdAtCell: TemplateRef<any>;
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("editHdrTmpl", { static: true }) editHdrTmpl: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("id");
  subscription = new Subscription();
  public userDetailsArray: DatahubUserDetails[] = [];

  constructor(
    public AllUsersService: AllUsersService,
    private UserService: UserService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    // this.AsignTypes();
    this.columns = [
      {
        name: "id",
        translationKey: "models.datahubUser.id",
        cellTemplate: this.idCell,
        filter: null,
        displayed: true,
        sort: "id",
      },
      {
        name: "firstName",
        translationKey: "models.datahubUser.firstName",
        cellTemplate: this.firstNameCell,
        filter: new TextFilterDef("firstNameFilter"),
        displayed: true,
        sort: "firstName",
      },
      {
        name: "lastName",
        translationKey: "models.datahubUser.lastName",
        cellTemplate: this.lastNameCell,
        filter: new TextFilterDef("nameFilter"),
        displayed: true,
        sort: "lastName",
      },
      {
        name: "email",
        translationKey: "models.datahubUser.email",
        cellTemplate: this.emailCell,
        filter: new TextFilterDef("emailFilter"),
        displayed: true,
        sort: "email",
      },
      {
        name: "status",
        translationKey: "models.datahubUser.status",
        cellTemplate: this.statusCell,
        filter: new SelectFilterDef(
          "userStatusFilter",
          Object.keys(DatahubUserStatus)
            .filter(
              (value) =>
                !isNaN(Number(Object.keys(DatahubUserStatus).indexOf(value)))
            )
            .map((status) => {
              return new SelectOption(
                Object.keys(DatahubUserStatus).indexOf(status).toString(),
                `models.datahubUser.statusses.${DatahubUserStatus[status]}`
              );
            })
        ),
        displayed: true,
        sort: "status",
      },
      {
        name: "roles",
        translationKey: "models.datahubUser.role",
        cellTemplate: this.rolesCell,
        filter: new SelectFilterDef(
          "userRoleFilter",
          Object.keys(UserRole)
            .filter(
              (value) => !isNaN(Number(Object.keys(UserRole).indexOf(value)))
            )
            .map(
              (role) =>
                new SelectOption(
                  Object.keys(UserRole).indexOf(role).toString(),
                  `roles.${UserRole[role]}`
                )
            )
        ),
        displayed: true,
        sort: null,
      },
      {
        name: "createdAt",
        translationKey: "models.datahubUser.createdAt",
        cellTemplate: this.createdAtCell,
        filter: null,
        displayed: true,
        sort: "createdAt",
      },
      {
        name: "actions.actions",
        translationKey: "models.datahubUser.details",
        cellTemplate: this.editTmpl,
        filter: null,
        displayed: true,
        sort: null,
      },
    ];
  }

  getRole(role: number) {
    return Object.keys(UserRole)[role];
  }

  getStatus(status: number) {
    return Object.values(DatahubUserStatus)[status];
  }

  showUserDetailsForId(userId) {
    if (
      this.userDetailsArray.every((ud) => ud.id !== userId) &&
      userId !== null &&
      userId !== undefined
    ) {
      this.UserService.getUserDetailsByUserId(userId).subscribe((details) =>
        this.userDetailsArray.push(details)
      );
    }
  }

  hideUserDetailsForId(id) {
    this.userDetailsArray = this.userDetailsArray.filter((ud) => ud.id !== id);
  }

  hideAllDetails() {
    this.userDetailsArray = [];
  }

  editFarm(id: string) {
    this.router.navigate([`farmmapping/edit-farmmapping/`, id]);
  }

  editPartner(id: string) {
    this.router.navigate([`manage-partners/manage-partner-details/`, id]);
  }

  private _colors: string[] = [
    "floralwhite",
    "lavenderblush",
    "lightcyan",
    "lavender",
    "paleturquoise",
  ];

  getColor(indexOfElement) {
    return this._colors[indexOfElement % this._colors.length];
  }

  viewUser(id: string) {
    this.router.navigate([`view-user`, id], {
      relativeTo: this.route,
    });
  }
}
