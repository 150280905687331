import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../../app/baseSettings';
import { Observable } from 'rxjs';
import { UserContextDTO } from '../../shared/models/userContextDTO';

@Injectable()
export class ContextService {
  private URL = `${this.api}/api/User`;
  constructor(
    private client: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getUserContexts(): Observable<Array<UserContextDTO>> {
    return this.client.get<Array<UserContextDTO>>(`${this.URL}/contexts`);
  }

  public setUserContext(context: UserContextDTO) {
    return this.client.post(`${this.URL}/context/${context.id}`, null);
  }

  public getContext(): Observable<UserContextDTO> {
      return this.client.get<UserContextDTO>(`${this.URL}/context`);
  }
}
