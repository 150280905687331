<button [ngClass]="{'card-button': true,'gray-color': data.informativeFlow, 'white-color': !data.informativeFlow}">
  <div class="card-row">
    <div class="upper-part">
      <img
        *ngIf="hasApplicationLogoUrl(); else dclogo"
        class="app-logo"
        src="{{ this.baseurl + data.applicationLogoUrl }}"
        [alt]="data.consumerName"
      />
      <ng-template #dclogo>
        <img src="/assets/images/blauwMiddel 1.svg" alt="blauwmiddel" />
      </ng-template>
      <h3>
        {{ data?.consumerName }}
      </h3>
    </div>
    <h4>{{ data.companyName }}</h4>
  </div>
  <div class="card-row">
    <p class="description" title="{{ data.consumerPurpose }}">
      {{ data.consumerPurpose }}
    </p>
    <div class="statistics">
      <div class="statistic green">
        <i class="fas fa-check-circle"></i>
        <p class="small">
          {{ data.approved }}
          {{ "models.accessRequest.statusses.Approved" | translate }}
        </p>
      </div>
      <div class="statistic red">
        <i class="fas fa-times-circle"></i>
        <p class="small">
          {{ data.rejected }}
          {{ "models.accessRequest.statusses.Rejected" | translate }}
        </p>
      </div>
    </div>
  </div>
  <p [ngClass]="{'go-to-page': true, 'pending': data.pending > 0 }">
    <span>
      {{ data.pending ? data.pending : null }}
      {{
        (data.pending
          ? "resourceAccessRequestPage.card.goToDetails"
          : "dataAccessRequestPage.card.noPending"
        ) | translate
      }}
    </span>
    <i class="fas fa-chevron-right"></i>
  </p>
</button>
