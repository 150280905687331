import { MatChipsModule } from "@angular/material/chips";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatTreeModule } from "@angular/material/tree";
import { AdminManageUsersComponent } from "./admin-manage-users/admin-manage-users.component";
import { NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { ManageAdminsComponent } from "./manage-admins/manage-admins.component";
import { RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ManagePartnersComponent } from "./manage-partners/manage-partners.component";
import { PartnerDetailsComponent } from "./manage-partners/partner-details/partner-details.component";
import { EditFarmMappingComponent } from "./farmmapping/edit-farmmapping/edit-farmmapping.component";
import { UserModule } from "../user/user.module";
import { RoleGuard } from "../shared/guards/role-guard";
import { ViewFarmAccessComponent } from "./view-farm-access/view-farm-access.component";
import { FarmMappingComponent } from "./farmmapping/farmmapping.component";
import { ViewResourceAccessComponent } from "./view-resource-access/view-resource-access.component";
import { ViewFarmResourceAccessComponent } from "./view-farm-resource-access/view-farm-resource-access.component";
import { AccessDecisionTranslation } from "../shared/pipes/access-decision-translation";
import { ManageUnclaimedInvitesComponent } from "./manage-unclaimed-invites/manage-unclaimed-invites.component";
import { FarmTypeTranslation } from "../shared/pipes/farm-type-translation";
import { ViewUserComponent } from "./admin-manage-users/view-user/view-user/view-user.component";
import { ReactiveFormsModule } from "@angular/forms";
import { ResourceHealthAdminComponent } from "./resource-health-admin/resource-health-admin.component";

@NgModule({
  declarations: [
    ManageAdminsComponent,
    ManagePartnersComponent,
    PartnerDetailsComponent,
    EditFarmMappingComponent,
    ViewFarmAccessComponent,
    FarmMappingComponent,
    ViewResourceAccessComponent,
    ViewFarmResourceAccessComponent,
    ManageUnclaimedInvitesComponent,
    AdminManageUsersComponent,
    ViewUserComponent,
    ResourceHealthAdminComponent,
  ],
  providers: [AccessDecisionTranslation, FarmTypeTranslation],
  imports: [
    SharedModule,
    UserModule,
    MatChipsModule,
    MatTreeModule,
    MatGridListModule,
    ReactiveFormsModule,
    RouterModule.forChild([
      {
        path: "admin-manage-users",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.adminManageUsers",
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: AdminManageUsersComponent,
            data: {
              breadcrumb: "",
              roles: ["Admin"],
            },
          },
          {
            path: "view-user/:id",
            component: ViewUserComponent,
            data: {
              breadcrumb: "navigation.viewUser",
              roles: ["Admin"],
            },
          },
        ],
      },
      {
        path: "manage-admins",
        canActivate: [MsalGuard, RoleGuard],
        component: ManageAdminsComponent,
        data: {
          breadcrumb: "navigation.manageAdmins",
          roles: ["Admin"],
        },
      },
      {
        path: "manage-partners",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.managePartners",
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ManagePartnersComponent,
            data: {
              breadcrumb: "",
              roles: ["Admin"],
            },
          },
          {
            path: "manage-partner-details",
            component: PartnerDetailsComponent,
            data: {
              breadcrumb: "navigation.partnerDetails",
              roles: ["Admin"],
            },
          },
          {
            path: "manage-partner-details/:id",
            component: PartnerDetailsComponent,
            data: {
              breadcrumb: "navigation.partnerDetails",
              roles: ["Admin"],
            },
          },
        ],
      },
      {
        path: "farm-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewFarmAccessComponent,
        data: {
          breadcrumb: "navigation.viewFarmAccess",
          roles: ["Admin"],
        },
      },
      {
        path: "farmmapping",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.farmMapping",
          roles: ["Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: FarmMappingComponent,
            data: {
              breadcrumb: "",
              roles: ["Admin"],
            },
          },
          {
            path: "edit-farmmapping",
            component: EditFarmMappingComponent,
            data: {
              breadcrumb: "navigation.editFarmmapping",
              roles: ["Admin"],
            },
          },
          {
            path: "edit-farmmapping/:id",
            component: EditFarmMappingComponent,
            data: {
              breadcrumb: "navigation.editFarmmapping",
              roles: ["Admin"],
            },
          },
        ],
      },
      {
        path: "resource-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewResourceAccessComponent,
        data: {
          breadcrumb: "navigation.viewResourceAccess",
          roles: ["Admin"],
        },
      },
      {
        path: "farm-resource-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewFarmResourceAccessComponent,
        data: {
          breadcrumb: "navigation.viewFarmResourceAccess",
          roles: ["Admin"],
        },
      },
      {
        path: "manage-unclaimed-invites",
        canActivate: [MsalGuard, RoleGuard],
        component: ManageUnclaimedInvitesComponent,
        data: {
          breadcrumb: "navigation.manageUnclaimedInvites",
          roles: ["Admin"],
        },
      },
      {
        path: "resource-health-admin",
        canActivate: [MsalGuard, RoleGuard],
        component: ResourceHealthAdminComponent,
        data: {
          breadcrumb: "navigation.consumerResourceHealth",
          roles: ["Admin"],
        },
      },
    ]),
  ],
  exports: [PartnerDetailsComponent, EditFarmMappingComponent],
})
export class AdminModule {}
