import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription, Observable } from "rxjs";
import { ApiResourceService } from "../../core/services/api-resource.service";
import { FarmIdType } from "../../data-provider-module/models/farm-id-type";
import { FarmIdTypesService } from "../../core/services/farmIdType.service";
import { ConsumerAccessService } from "../../core/services/consumer-access.service";
import { ConsumerAccess } from "../models/consumer-access";
import { ApiResource, ApiResourceDisplayModel } from "../models/apiResource";
import { CanComponentDeactivate } from "../../shared/guards/can-deactivate-guard";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Language } from "../../shared/models/language";

@Component({
  selector: "app-consumer-access",
  templateUrl: "./consumer-access.component.html",
  styleUrls: ["./consumer-access.component.scss"],
})
export class ConsumerAccessComponent
  implements OnInit, OnDestroy, CanComponentDeactivate
{
  private initialFarmIds = new Array<string>();
  constructor(
    private consumerAccessService: ConsumerAccessService,
    private apiResourceService: ApiResourceService,
    private farmIdTypesService: FarmIdTypesService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe((l: LangChangeEvent) => {
      this.currentLanguage = Language[l.lang];
      this.setTranslatedResources();
    });
    this.currentLanguage = Language[this.translate.currentLang];
    this.allResources = [];
  }

  currentLanguage: Language;
  subscription = new Subscription();
  consumerAccess = new ConsumerAccess();
  consumerAccessInitSnapshot: ConsumerAccess;
  notBefore = new Date();
  farmIdTypes = Array<FarmIdType>();
  translatedResources = Array<ApiResourceDisplayModel>();
  allResources = Array<ApiResource>();

  ngOnInit() {
    this.notBefore.setDate(this.notBefore.getDate() + 1);

    this.initConsumerAccess();
    this.initFarmIdTypes();
    this.initResources();
  }

  private initResources() {
    this.subscription.add(
      this.apiResourceService.getAll().subscribe((response) => {
        this.allResources = response;
        this.setTranslatedResources();
      })
    );
  }

  private setTranslatedResources() {
    this.translatedResources = this.allResources.map((r) => {
      const trans = r.translations.find((t) =>
        this.currentLanguage
          ? t.language === this.currentLanguage
          : t.language === Language.nl
      );
      return new ApiResourceDisplayModel(
        r.id,
        r.type,
        trans.name,
        trans.providerApiName
      );
    });
  }

  private initConsumerAccess() {
    this.subscription.add(
      this.consumerAccessService.get().subscribe((consumerAccess) => {
        if (
          consumerAccess !== undefined &&
          consumerAccess !== null &&
          consumerAccess.id !== undefined &&
          consumerAccess.id !== null
        ) {
          this.consumerAccess = consumerAccess;
          this.consumerAccess.resourcesIds = consumerAccess.resources.map(
            (resource) => resource.id
          );

          this.consumerAccess.farmIdType = Object.assign(
            new FarmIdType(),
            this.consumerAccess.farmIdType
          );
          this.initialFarmIds = this.consumerAccess.farmsIds || [];
        }
        this.createSnapshot();
      })
    );
  }

  private createSnapshot() {
    this.consumerAccessInitSnapshot = Object.assign(
      new ConsumerAccess(),
      this.consumerAccess
    );
  }

  private initFarmIdTypes() {
    this.subscription.add(
      this.farmIdTypesService.getAll().subscribe((response) => {
        this.farmIdTypes = response;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  canDeactivate(): boolean | Observable<boolean> {
    if (this.isFormDirty()) {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        disableClose: false,
        data: {
          title: "manageConsumerAccessPage.warning",
        },
      });
      return dialogRef.afterClosed();
    }
    return true;
  }

  areFarmIdsDirty(): boolean {
    return (
      JSON.stringify(this.initialFarmIds) !==
      JSON.stringify(this.consumerAccess.farmsIds)
    );
  }

  private isFormDirty(): boolean {
    return (
      JSON.stringify(this.consumerAccessInitSnapshot) !==
      JSON.stringify(this.consumerAccess)
    );
  }

  onFarmAdd($event) {
    let farms = this.consumerAccess.farmsIds;
    const isNew = !farms.some((farm) => farm === $event);
    if (isNew) {
      farms = [...farms, $event];
    }
  }

  onFarmRemove($event) {
    let farms = this.consumerAccess.farmsIds;
    const isNew = farms.some((r) => r === $event.label);
    if (isNew) {
      farms = farms.filter((farm) => farm !== $event.label);
    }
  }

  canSpecifyFarms(): boolean {
    if (this.consumerAccess?.areAllFarmsSelected) {
      return !this.consumerAccess.areAllFarmsSelected;
    }
    return true;
  }

  canCheckAllFarms(): boolean {
    return (
      this.consumerAccess.farmsIds === undefined ||
      this.consumerAccess.farmsIds === null ||
      this.consumerAccess.farmsIds.length === 0
    );
  }

  //TEST

  canSubmit(): boolean {
    return (
      this.consumerAccess !== undefined &&
      this.consumerAccess !== null &&
      this.existSelectedResources() &&
      this.existSelectedFarms() &&
      this.isFormDirty()
    );
  }

  private existSelectedResources(): boolean {
    return (
      this.consumerAccess.resourcesIds !== undefined &&
      this.consumerAccess.resourcesIds !== null &&
      this.consumerAccess.resourcesIds.length > 0
    );
  }

  private existSelectedFarms(): boolean {
    return (
      (this.consumerAccess.farmsIds !== undefined &&
        this.consumerAccess.farmsIds !== null &&
        this.consumerAccess.farmsIds.length > 0) ||
      this.consumerAccess.areAllFarmsSelected
    );
  }

  compareFarmIdType(val1: FarmIdType, val2: FarmIdType) {
    return (
      val1 !== undefined &&
      val1 !== null &&
      val2 !== undefined &&
      val2 !== null &&
      val1.id === val2.id
    );
  }

  onAllFarmsClick() {
    if (this.consumerAccess.areAllFarmsSelected) {
      this.consumerAccess.farmIdType = null;
    } else {
      this.consumerAccess.farmIdType = this.farmIdTypes.find(
        (f) => f.isDefault
      );
    }
  }

  onSave() {
    // remove duplicates
    this.consumerAccess.farmsIds = [...new Set(this.consumerAccess.farmsIds)];
    this.consumerAccess.resources = this.consumerAccess.resourcesIds.map(id => {
      const api = new ApiResource();
      api.id = id;
      return api;
    });
    this.subscription.add(
      this.consumerAccessService
        .save(this.consumerAccess)
        .subscribe(() => {
          this.createSnapshot();
          this.initialFarmIds = this.consumerAccess?.farmsIds || [];
        })
    );
  }
}
