import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SharedModule } from "../shared/shared.module";
import { RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { RoleGuard } from "../shared/guards/role-guard";
import { ConsumerAccessComponent } from "./manage-access/consumer-access.component";
import { CanDeactivateGuard } from "../shared/guards/can-deactivate-guard";
import { PartnerModule } from "../partner/partner.module";
import { ViewFarmAccessComponent } from "./view-farm-access/view-farm-access.component";
import { ViewResourceAccessComponent } from "./view-resource-access/view-resource-access.component";
import { ViewFarmResourceAccessComponent } from "./view-farm-resource-access/view-farm-resource-access.component";
import { FarmMappingStatusTranslation } from "../shared/pipes/farm-mapping-status";
import { ConsumerPushEventsComponent } from "./push-events/consumer-push-events.component";
import { ResourceHealthComponent } from "./resource-health/resource-health.component";
import { ConsumerManageFarmidsComponent } from "./manage-access/consumer-manage-farmids/consumer-manage-farmids.component";
import { AddFarmidsComponent } from "./manage-access/consumer-manage-farmids/add-farmids/add-farmids.component";
import { MatChipsModule } from "@angular/material/chips";

@NgModule({
  declarations: [
    ConsumerAccessComponent,
    ViewFarmAccessComponent,
    ViewResourceAccessComponent,
    ViewFarmResourceAccessComponent,
    ConsumerPushEventsComponent,
    ResourceHealthComponent,
    ConsumerManageFarmidsComponent,
    AddFarmidsComponent,
  ],
  providers: [FarmMappingStatusTranslation],
  imports: [
    CommonModule,
    SharedModule,
    MatChipsModule,
    PartnerModule,
    RouterModule.forChild([
      {
        path: "consumer-access",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.manageAccess",
          roles: ["Consumer"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ConsumerAccessComponent,
            canDeactivate: [CanDeactivateGuard],
            data: {
              breadcrumb: "",
              roles: ["Consumer"],
            },
          },
        ],
      },
      {
        path: "consumer-farm-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewFarmAccessComponent,
        data: {
          breadcrumb: "navigation.viewFarmAccess",
          roles: ["Consumer"],
        },
      },
      {
        path: "consumer-resource-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewResourceAccessComponent,
        data: {
          breadcrumb: "navigation.viewResourceAccess",
          roles: ["Consumer"],
        },
      },
      {
        path: "consumer-farm-resource-access",
        canActivate: [MsalGuard, RoleGuard],
        component: ViewFarmResourceAccessComponent,
        data: {
          breadcrumb: "navigation.viewFarmResourceAccess",
          roles: ["Consumer"],
        },
      },
      {
        path: "consumer-push-events",
        canActivate: [MsalGuard, RoleGuard],
        component: ConsumerPushEventsComponent,
        data: {
          breadcrumb: "navigation.consumerPushEvents",
          roles: ["Consumer"],
        },
      },
      {
        path: "consumer-resource-health",
        canActivate: [MsalGuard, RoleGuard],
        component: ResourceHealthComponent,
        data: {
          breadcrumb: "navigation.consumerResourceHealth",
          roles: ["Consumer"],
        },
      },
    ]),
  ]
})
export class DataConsumerModule {}
