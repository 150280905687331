import { Component, Input, OnInit } from "@angular/core";
import { UserAuthService } from "../../core/user-auth.service";

@Component({
  selector: "app-relog-link",
  templateUrl: "./relog-link.component.html",
  styleUrls: ["./relog-link.component.scss"],
})
export class RelogLinkComponent implements OnInit {
  constructor(private auth: UserAuthService) {}

  @Input() linkText: string = "actions.relog";
  
  ngOnInit() {}

  relog() {
    this.auth.relog();
  }
}
