import {
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import {
  DatahubUserDetails,
  UserDetailFarmMappingDTO,
  UserRole,
} from "../../shared/models/datahubUser";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { AllUsersService } from "../../core/services/all-users.service";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { Observable, Subject } from "rxjs";
import { UserService } from "../../core/services/user.service";
import { FarmResourceAccessService } from "../../core/services/farm-resource-access.service";
import { DarStatusSummary } from "../../data-consumer/models/dar-status-summary";
import { RemoteDataSource } from "../../shared/base-remote-datatable/remote-data-source";
import { AccessRequestStatus } from "../../shared/models/access-request-status";
import { DarStatus } from "../../shared/models/data-access-request-status";
import { SelectFilter } from "../../shared/base-datatable/models/select-filter";
import { BaseDatatableComponent } from "../../shared/base-datatable/base-datatable.component";
import { FarmResourceAccessStatusTranslation } from "../../shared/pipes/farm-resource-access-status-translation";
import { AccessRequestStatusTranslation } from "../../shared/pipes/access-request-status";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-admin-helpdesk-table-users",
  templateUrl: "./admin-helpdesk-table-users.component.html",
  styleUrls: ["./admin-helpdesk-table-users.component.scss"],
})
export class AdminHelpdeskTableUsersComponent implements OnInit, OnDestroy {
  // Table for users
  columns: Array<ColumnDef>;
  initialSort = new InitialSort("lastName");
  @ViewChild("firstNameCell", { static: true }) firstNameCell: TemplateRef<any>;
  @ViewChild("lastNameCell", { static: true }) lastNameCell: TemplateRef<any>;
  @ViewChild("emailCell", { static: true }) emailCell: TemplateRef<any>;
  @ViewChild("rolesCell", { static: true }) rolesCell: TemplateRef<any>;
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("dialogDetails", { static: true })
  dialogDetails: ElementRef<HTMLDialogElement>;
  loadingData: boolean = false;

  // Table for dialog detailed user information
  columnsFarms: Array<any>;
  rowsFarms: Observable<Array<DarStatusSummary>> = new Observable();
  @ViewChild(BaseDatatableComponent, { static: true })
  farmAccessTable: BaseDatatableComponent;
  @ViewChild("kboNumberCell", { static: true }) kboNumberCell: TemplateRef<any>;
  @ViewChild("resourceNameCell", { static: true })
  resourceNameCell: TemplateRef<any>;
  @ViewChild("resourceStatusCell", { static: true })
  resourceStatusCell: TemplateRef<any>;
  @ViewChild("darStatusCell", { static: true }) darStatusCell: TemplateRef<any>;
  farmResourceAccessStatusTranslation =
    new FarmResourceAccessStatusTranslation();
  accessRequestStatusTranslation = new AccessRequestStatusTranslation();

  loading: boolean = false;
  details$: Observable<DatahubUserDetails>;
  detailsFarmAccess$: RemoteDataSource<any> = null;
  detailsKboData = new Observable<Array<DarStatusSummary>>();
  selectedKboNumber: string;

  constructor(
    public AllUsersService: AllUsersService,
    public farmResourceAccessService: FarmResourceAccessService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.initColumns();
    this.initColumnsFarms();

    // Close the dialog window when clicking outside of it
    this.dialogDetails.nativeElement.addEventListener(
      "click",
      (event: MouseEvent) => {
        if (event.target === this.dialogDetails.nativeElement)
          this.lightDismiss(this.dialogDetails.nativeElement);
      }
    );
  }

  // Close the dialog window when clicking outside of it
  private lightDismiss = (dialog: HTMLDialogElement) => {
    if (dialog.nodeName === "DIALOG") this.closeModal();
  };

  ngOnDestroy() {
    this.closeModal();
  }

  getRole(role: number): string {
    return Object.keys(UserRole)[role];
  }

  getUserDetails(id: string): void {
    this.details$ = this.userService.getUserDetailsByUserId(id);
    if (!this.details$) {
      console.error("Failed to fetch user details");
    }
  }

  closeModal(): void {
    this.dialogDetails.nativeElement.close();
    this.details$ = null;
    this.detailsFarmAccess$ = null;
    this.document.querySelector("html").style.overflow = "auto";
    this.document.body.style.overflow = "auto";
    this.loadingData = false;
  }

  dialogIsOpen(): boolean {
    return this.dialogDetails.nativeElement.open;
  }

  viewUser(id: string): void {
    this.loadingData = true;
    this.accumulatedData = [];
    // Opens the dialog window with detailed user information
    this.dialogDetails.nativeElement.show();
    this.document.querySelector("html").style.overflow = "hidden";
    this.document.body.style.overflow = "hidden";

    this.getUserDetails(id);
    this.rowsFarms = new Observable();

    this.details$?.subscribe((details) => {
      details.farms.forEach((farm, index) => {
        // Set the selected KBO number and load the data for the farm access table
        this.selectedKboNumber = farm.kbo;

        Object.values(farm.mappings).forEach((key, index_farmMapping) => {
          switch (key.type) {
            case "KBO":
              this.setDataSource(key.value);
              return;
            default:
              break;
          }
        });
      });
    });
  }

  accumulatedData: any[] = [];
  setDataSource(id: string) {
    this.detailsFarmAccess$ = new RemoteDataSource<any>(
      this.farmResourceAccessService
    );
    // Load the data for the farm access table with the selected KBO number
    // Biggest page size - 150 - to get all the data at once
    this.detailsFarmAccess$.loadData(1, 150, null, {
      kboNumberFilter: id,
    });
    this.detailsKboData = this.detailsFarmAccess$.getData();
    this.detailsKboData.subscribe((data) => {
      this.accumulatedData = this.accumulatedData.concat(data);
      this.rowsFarms = new Observable((observer) => {
        this.accumulatedData = this.accumulatedData.concat(data);
        // Push the data to the observer array
        observer.next(this.accumulatedData);
      });
      this.initColumnsFarms();
    });
  }

  groupFarmMappingsByType(
    mappings: UserDetailFarmMappingDTO[]
  ): Map<string, string[]> {
    let groups = new Map<string, string[]>();
    mappings.forEach((mapping) => {
      let group = groups.get(mapping.type);
      if (group !== undefined) return group.push(mapping.value);
      groups.set(mapping.type, [mapping.value]);
    });
    return groups;
  }

  private initColumns = () => {
    this.columns = [
      {
        name: "actions.actions",
        translationKey: "models.datahubUser.details",
        cellTemplate: this.editTmpl,
        filter: null,
        displayed: true,
        sort: null,
      },
      {
        name: "firstName",
        translationKey: "models.datahubUser.firstName",
        cellTemplate: this.firstNameCell,
        filter: new TextFilterDef("firstNameFilter"),
        displayed: true,
        sort: "firstName",
      },
      {
        name: "lastName",
        translationKey: "models.datahubUser.lastName",
        cellTemplate: this.lastNameCell,
        filter: new TextFilterDef("nameFilter"),
        displayed: true,
        sort: "lastName",
      },
      {
        name: "email",
        translationKey: "models.datahubUser.email",
        cellTemplate: this.emailCell,
        filter: new TextFilterDef("emailFilter"),
        displayed: true,
        sort: "email",
      },
      {
        name: "roles",
        translationKey: "models.datahubUser.role",
        cellTemplate: this.rolesCell,
        filter: new SelectFilterDef(
          "userRoleFilter",
          Object.keys(UserRole)
            .filter(
              (value) => !isNaN(Number(Object.keys(UserRole).indexOf(value)))
            )
            .map(
              (role) =>
                new SelectOption(
                  Object.keys(UserRole).indexOf(role).toString(),
                  `roles.${UserRole[role]}`
                )
            )
        ),
        displayed: true,
        sort: null,
      },
    ];
  };

  private initColumnsFarms = () => {
    this.columnsFarms = [
      {
        prop: "kboNumber",
        name: "models.farmResourceAccess.farmResourceAccessTable.kboNumber",
        headerTemplate: this.farmAccessTable.hdrTextTpl,
        flexGrow: 3,
        sortable: false,
      },
      {
        prop: "resourceName",
        name: "models.farmResourceAccess.farmResourceAccessTable.resourceName",
        headerTemplate: this.farmAccessTable.hdrTextTpl,
        flexGrow: 3,
        sortable: true,
      },
      {
        prop: "resourceStatus",
        name: "models.farmResourceAccess.farmResourceAccessTable.resourceStatus",
        headerTemplate: this.farmAccessTable.hdrSelectTpl,
        cellTemplate: this.resourceStatusCell,
        flexGrow: 3,
        sortable: true,
        data: Object.keys(AccessRequestStatus)
          .filter((value) => isNaN(Number(value)) === false)
          .map((x) => {
            return new SelectFilter(
              AccessRequestStatus[x],
              this.accessRequestStatusTranslation.transform(
                AccessRequestStatus[x]
              )
            );
          }),
      },
      {
        prop: "darStatus",
        name: "models.farmResourceAccess.farmResourceAccessTable.darStatus",
        headerTemplate: this.farmAccessTable.hdrSelectTpl,
        cellTemplate: this.darStatusCell,
        flexGrow: 3,
        sortable: true,
        data: Object.keys(DarStatus)
          .filter((value) => isNaN(Number(value)) === false)
          .map((x) => {
            return new SelectFilter(
              DarStatus[x],
              this.farmResourceAccessStatusTranslation.transform(DarStatus[x])
            );
          }),
      },
    ];
  };
}
