import { baseurl, appVersion, environment, externalAuthority } from './../../baseSettings';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { NotificationService } from './services/notification.service';
import { ValidatorService } from './validators/validator-service';
import { UserService } from './services/user.service';
import { SpinnerInterceptor } from './interceptors/spinner-http-interceptor';
import { ApiService } from './services/api.service';
import { FarmIdTypesService } from './services/farmIdType.service';
import { InvitationService } from './services/invitation.service';
import { PartnerService } from './services/partner.service';
import { ProductService } from './services/product.service';
import { ApiResourceService } from './services/api-resource.service';
import { ResourceAccessRequestService } from './services/resourceAccessRequest.service';
import { FarmService } from './services/farm.service';
import { FarmDetailsService } from './services/farm-details.service';
import { DataAccessRequestService } from './services/data-access-request.service';
import { ContextHeaderInterceptor } from './interceptors/add-context-http-interceptor';
import { ContextService } from './services/context.service';
import { UserAuthService } from './user-auth.service';
import { FarmAccessService } from './services/farm-access.service';
import { TagService } from './services/tag.service';
import { FarmMappingService } from './services/farmmapping.service';
import { SpinnerService } from './services/spinner.service';

export function baseUrlValueFactory(): string {
  const appConfig = JSON.parse(sessionStorage.getItem('appConfig'));
  return appConfig.platformAPI;
}

export function externalAuthorityFactory(): string {
  const appConfig = JSON.parse(sessionStorage.getItem('appConfig'));
  return appConfig.externalAuthority;
}

export function versionFactory(): string {
  const appConfig = JSON.parse(sessionStorage.getItem('appConfig'));
  return appConfig.BuildConfig.BuildNumber;
}

export function environmentFactory(): string {
  const appConfig = JSON.parse(sessionStorage.getItem('appConfig'));
  return appConfig.Environment;
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatSnackBarModule
  ],
  declarations: [],
  exports: [
    BrowserModule,
    BrowserAnimationsModule
  ],
  providers: [
    NotificationService,
    ApiService,
    ContextService,
    UserService,
    InvitationService,
    ValidatorService,
    FarmIdTypesService,
    PartnerService,
    ProductService,
    TagService,
    ApiResourceService,
    ResourceAccessRequestService,
    FarmService,
    FarmDetailsService,
    FarmAccessService,
    FarmMappingService,
    DataAccessRequestService,
    UserAuthService,
    SpinnerService,
    {
      provide: baseurl,
      useFactory: baseUrlValueFactory
    },
    {
      provide: appVersion,
      useFactory: versionFactory
    },
    {
      provide: environment,
      useFactory: environmentFactory
    },
    {
      provide: externalAuthority,
      useFactory: externalAuthorityFactory
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SpinnerInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ContextHeaderInterceptor,
      multi: true,
    }
  ]
})


export class CoreModule {}
