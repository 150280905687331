import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import { FarmStatus } from "../../data-consumer/models/farm-status-summary";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { AccessRequestStatus } from "../../shared/models/access-request-status";
import { DarStatus } from "../../shared/models/data-access-request-status";
import { FarmResourceAccessService } from "../../core/services/farm-resource-access.service";

@Component({
  selector: "app-view-farm-resource-access",
  templateUrl: "./view-farm-resource-access.component.html",
  styleUrls: ["./view-farm-resource-access.component.css"],
})
export class ViewFarmResourceAccessComponent implements OnInit {
  @ViewChild("partnerNameCell", { static: true })
  partnerNameCell: TemplateRef<any>;
  @ViewChild("farmNumberCell", { static: true })
  farmNumberCell: TemplateRef<any>;
  @ViewChild("kboNumberCell", { static: true }) kboNumberCell: TemplateRef<any>;
  @ViewChild("resourceNameCell", { static: true })
  resourceNameCell: TemplateRef<any>;
  @ViewChild("farmStatusCell", { static: true })
  farmStatusCell: TemplateRef<any>;
  @ViewChild("resourceStatusCell", { static: true })
  resourceStatusCell: TemplateRef<any>;
  @ViewChild("darStatusCell", { static: true }) darStatusCell: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("partnerName");

  constructor(public farmResourceAccessService: FarmResourceAccessService) {}

  ngOnInit() {
    this.columns = [
      {
        name: "partnerName",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.partnerName",
        cellTemplate: this.partnerNameCell,
        filter: new TextFilterDef("partnerNameFilter"),
        displayed: true,
        sort: "partnerName",
      },
      {
        name: "farmNumber",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.farmNumber",
        cellTemplate: this.farmNumberCell,
        filter: new TextFilterDef("farmNumberFilter"),
        displayed: true,
        sort: "farmNumber",
      },
      {
        name: "kboNumber",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.kboNumber",
        cellTemplate: this.kboNumberCell,
        filter: new TextFilterDef("kboNumberFilter"),
        displayed: true,
        sort: "kboNumber",
      },
      {
        name: "resourceName",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.resourceName",
        cellTemplate: this.resourceNameCell,
        filter: new TextFilterDef("resourceNameFilter"),
        displayed: true,
        sort: "resourceName",
      },
      {
        name: "farmStatus",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.farmStatus",
        cellTemplate: this.farmStatusCell,
        filter: new SelectFilterDef(
          "farmStatusFilter",
          Object.keys(FarmStatus)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.farmAccess.statuses.${FarmStatus[x]}`
                )
            )
        ),
        displayed: true,
        sort: "farmStatus",
      },
      {
        name: "resourceStatus",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.resourceStatus",
        cellTemplate: this.resourceStatusCell,
        filter: new SelectFilterDef(
          "resourceStatusFilter",
          Object.keys(AccessRequestStatus)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.accessRequest.statusses.${AccessRequestStatus[x]}`
                )
            )
        ),
        displayed: true,
        sort: "resourceStatus",
      },
      {
        name: "darStatus",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.darStatus",
        cellTemplate: this.darStatusCell,
        filter: new SelectFilterDef(
          "darStatusFilter",
          Object.keys(DarStatus)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.farmResourceAccess.statuses.${DarStatus[x]}`
                )
            )
        ),
        displayed: true,
        sort: "darStatus",
      },
    ];
  }
}
