<h1>{{ "navigation.adminManageUsers" | translate }}</h1>
<p>{{ "info.manageUsers" | translate }}</p>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="AllUsersService"
  [exportable]="false"
  queryOnLangChange="true"
  [lastColumnIcon]="true"
/>

<ng-template #idCell let-id="id">
  <button
    class="details-button"
    (click)="showUserDetailsForId(id)"
    style="padding: 0.25rem !important"
  >
    {{ id }}
  </button>
</ng-template>

<ng-template #firstNameCell let-firstName="firstName">
  <p class="text-cell">{{ firstName }}</p>
</ng-template>

<ng-template #lastNameCell let-lastName="lastName">
  <p class="text-cell">{{ lastName }}</p>
</ng-template>

<ng-template #emailCell let-email="email">
  <p class="text-cell">{{ email }}</p>
</ng-template>

<ng-template #statusCell let-status="status">
  <p class="text-cell">
    {{ "models.datahubUser.statusses." + getStatus(status) | translate }}
  </p>
</ng-template>

<ng-template #rolesCell let-roles="roles">
  <ul>
    <li *ngFor="let role of roles">
      {{ "roles." + getRole(role) | translate }}
    </li>
  </ul>
</ng-template>

<ng-template #createdAtCell let-createdAt="createdAt">
  <p class="text-cell" *ngIf="createdAt; else noCreatedAt">
    {{ createdAt | date : "dd/MM/yyyy" }}
  </p>
  <ng-template #noCreatedAt>
    <p>/</p>
  </ng-template>
</ng-template>

<ng-template #editTmpl let-id="id">
  <button
    (click)="viewUser(id)"
    class="card-button view-user"
    aria-label="view user"
  >
    <i class="fa fa-eye"></i>
  </button>
</ng-template>

<ng-template [ngIf]="userDetailsArray.length > 0">
  <button class="green" (click)="hideAllDetails()" id="hideAllDetails">
    {{ "models.farmMapping.hideAll" | translate }}
  </button>
</ng-template>
<div class="horizontal">
  <div
    class="user-details"
    [ngStyle]="{ background: getColor(indexOfElement) }"
    *ngFor="let user of userDetailsArray; let indexOfElement = index"
  >
    <div class="header">
      <h2>Id:</h2>
      <p class="bold">
        {{ user.id ? user.id : "--" }}
      </p>
      <button
        (click)="hideUserDetailsForId(user.id)"
        class="close-user-details"
        style="padding: 0.5rem !important"
        aria-label="Close user details"
      >
        &#x274c;
      </button>
    </div>
    <form class="item">
      <label [for]="'user-partner-' + user.id">
        <h3>
          {{ "viewUserPage.partners" | translate }}
        </h3>
      </label>
      <input
        type="checkbox"
        [name]="'user-partner-' + user.id"
        [id]="'user-partner-' + user.id"
      />
      <div class="expandable">
        <ul *ngIf="user.partners.length; else noPartners">
          <li *ngFor="let partner of user.partners">
            <p>
              {{ partner }}
            </p>
            <button
              (click)="editPartner(partner)"
              aria-label="Edit partner"
              style="padding: 0.25rem !important"
            >
              <ng-template [ngTemplateOutlet]="editIcon"></ng-template>
            </button>
          </li>
        </ul>
      </div>

      <label [for]="'user-farm-' + user.id">
        <h3>
          {{ "viewUserPage.farms" | translate }}
        </h3>
      </label>
      <input
        type="checkbox"
        [name]="'user-farm-' + user.id"
        [id]="'user-farm-' + user.id"
      />
      <div class="expandable">
        <ul *ngIf="user.farms.length; else noFarms">
          <li *ngFor="let farm of user.farms">
            <p>
              {{ farm.id }}
            </p>
            <button
              (click)="editFarm(farm.id)"
              aria-label="Edit farm"
              style="padding: 0.25rem !important"
            >
              <ng-template [ngTemplateOutlet]="editIcon"></ng-template>
            </button>
          </li>
        </ul>
      </div>
    </form>
    <div class="item">
      <mat-card-footer> </mat-card-footer>
    </div>
  </div>
</div>

<ng-template #noFarms>
  <div>
    <p>{{ "viewUserPage.noFarms" | translate }}</p>
  </div>
</ng-template>

<ng-template #noPartners>
  <div>
    <p>{{ "viewUserPage.noPartners" | translate }}</p>
  </div>
</ng-template>

<ng-template #editIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-pencil-square"
    viewBox="0 0 16 16"
  >
    <path
      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
    />
    <path
      fill-rule="evenodd"
      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    />
  </svg>
</ng-template>
