import { Component, Input, OnInit } from "@angular/core";
import { AdminService } from "../admin.service";
import { AdminStatistics } from "../../shared/models/admin-statistics";
import { Observable, take } from "rxjs";
import { ChartData, ChartOptions } from "chart.js";

@Component({
  selector: "app-admin-statistics",
  templateUrl: "./admin-statistics.component.html",
  styleUrl: "./admin-statistics.component.scss",
})
export class AdminStatisticsComponent implements OnInit {
  @Input() loadingData: boolean;

  adminStatistics: Observable<AdminStatistics> = null;
  columnsListKboWithoutDataRequests: string[] = [
    "id",
    "farmIdTypeId",
    "KBO",
    "farmId",
  ];
  constructor(private adminService: AdminService) {
    this.adminStatistics = this.adminService.getAdminStatistics();

    this.adminStatistics.pipe(take(1)).subscribe((data) => {
      // Refresh the chart if the data comes
      this.dataFarmersWeek.datasets[0].data = this.totalPerDay(
        "week",
        data
      ).map((x) => x.value);
      this.dataFarmersMonth.datasets[0].data = this.totalPerDay(
        "month",
        data
      ).map((x) => x.value);
      this.dataFarmersWeekWithConsent.datasets[0].data = this.totalPerDay(
        "weekConsent",
        data
      ).map((x) => x.value);
    });
  }
  ngOnInit() {}

  private dateKey(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  private weekLabels = (): string[] => {
    let a = new Array<string>();
    for (let i = 0; i < 7; i++) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      a.push(this.dateKey(date));
    }
    return a.reverse();
  };
  private monthLabels = (): string[] => {
    let a = new Array<string>();
    for (let i = 0; i < 30; i++) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      a.push(this.dateKey(date));
    }
    return a.reverse();
  };

  private totalPerDay = (
    type: "week" | "month" | "weekConsent",
    data: AdminStatistics
  ): Array<{ date: string; value: number }> => {
    if (!this.adminStatistics) return [];

    // Fill the array with the last 7 days
    let a = new Array<{ date: string; value: number }>();
    switch (type) {
      case "week":
        a = this.weekLabels().map((x) => {
          return { date: x, value: 0 };
        });
        data.newFarmersWeek.forEach((element) => {
          let dateKey = this.dateKey(new Date(element.createdAt));
          let index = a.findIndex((x) => x.date === dateKey);
          if (index !== -1) a[index].value++;
        });
        return a.sort((a, b) => a.date.localeCompare(b.date));
      case "weekConsent":
        a = this.weekLabels().map((x) => {
          return { date: x, value: 0 };
        });
        data.newFarmersWeekWithConsent.forEach((element) => {
          let dateKey = this.dateKey(new Date(element.createdAt));
          let index = a.findIndex((x) => x.date === dateKey);
          if (index !== -1) a[index].value++;
        });
        return a.sort((a, b) => a.date.localeCompare(b.date)).map((x) => x);
      case "month":
        a = this.monthLabels().map((x) => {
          return { date: x, value: 0 };
        });
        data.newFarmersMonth.forEach((element) => {
          let dateKey = this.dateKey(new Date(element.createdAt));
          let index = a.findIndex((x) => x.date === dateKey);
          if (index !== -1) a[index].value++;
        });
        return a.sort((a, b) => a.date.localeCompare(b.date)).map((x) => x);
      default:
        return null;
    }
  };

  public dataFarmersWeek: ChartData = {
    datasets: [
      {
        label: "New Farmers week",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    labels: this.weekLabels(),
    xLabels: this.weekLabels(),
    yLabels: [],
  };
  public dataFarmersMonth: ChartData = {
    datasets: [
      {
        label: "New Farmers month",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    labels: this.monthLabels(),
    xLabels: this.monthLabels(),
    yLabels: [],
  };
  public dataFarmersWeekWithConsent: ChartData = {
    datasets: [
      {
        label: "New Farmers week with consent",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    labels: this.weekLabels(),
    xLabels: this.weekLabels(),
    yLabels: [],
  };

  public options: ChartOptions = {
    font: {
      family: "Raleway",
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    color: "#1b1b1b",
    datasets: {
      line: {
        fill: true,
        tension: 0.4,
      },
    },
    maintainAspectRatio: false  //cause shivering of the chart
  };
}
