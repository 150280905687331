import { NgModule } from "@angular/core";
import { MatGridListModule } from "@angular/material/grid-list";
import { CommonModule } from "@angular/common";
import { ManageApisComponent } from "./manage-apis/manage-apis.component";
import { RouterModule } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { RoleGuard } from "../shared/guards/role-guard";
import { SharedModule } from "../shared/shared.module";
import { AddApiDialogComponent } from "./manage-apis/add-api-dialog/add-api-dialog.component";
import { ApiDetailsComponent } from "./manage-apis/api-details/api-details.component";
import { ResourceComponent } from "./manage-apis/api-details/resource/resource.component";
import { PublishApiDialogComponent } from "./manage-apis/publish-api-dialog/publish-api-dialog.component";
import { ValidationResultDialogComponent as ValidationResultDialogComponentAPIs } from "./manage-apis/api-details/validation-result-dialog/validation-result-dialog.component";
import { ValidationResultDialogComponent as ValidationResultDialogComponentPushEvents } from "./manage-push-events/validation-result-dialog/validation-result-dialog.component";
import { ResourceAccessRequestsComponent } from "./resource-access-requests/resource-access-requests.component";
import { PartnerModule } from "../partner/partner.module";
import { ApiHeaderComponent } from "./manage-apis/api-details/api-header/api-header.component";
import { ManagePushEventsComponent } from "./manage-push-events/manage-push-events.component";
import { EventTypeComponent } from "./manage-push-events/event-type/event-type.component";
import { AddHeadersDialogComponent } from "./manage-push-events/event-type/add-headers-dialog/add-headers-dialog.component";
import { ManageHeadersComponent } from "./manage-apis/api-details/manage-headers/manage-headers.component";
import { CredentialsComponent } from "./manage-apis/api-details/credentials/credentials.component";
import { ResourceRequestsCardComponent } from "./resource-access-requests/resource-requests-card/resource-requests-card.component";
import { ProviderRarsComponent } from "./resource-access-requests/provider-rars/provider-rars.component";
@NgModule({
  declarations: [
    ManageApisComponent,
    AddApiDialogComponent,
    ApiDetailsComponent,
    ResourceComponent,
    ApiHeaderComponent,
    ValidationResultDialogComponentAPIs,
    PublishApiDialogComponent,
    ResourceAccessRequestsComponent,
    ManagePushEventsComponent,
    EventTypeComponent,
    ValidationResultDialogComponentPushEvents,
    AddHeadersDialogComponent,
    ManageHeadersComponent,
    CredentialsComponent,
    ResourceRequestsCardComponent,
    ProviderRarsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    PartnerModule,
    MatGridListModule,
    RouterModule.forChild([
      {
        path: "manage-apis",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.manageAPIs",
          roles: ["Provider", "Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ManageApisComponent,
            data: {
              breadcrumb: "",
              roles: ["Provider", "Admin"],
            },
          },
          {
            path: "api-details/:id",
            component: ApiDetailsComponent,
            data: {
              breadcrumb: "navigation.apiDetails",
              roles: ["Provider", "Admin"],
            },
          },
        ],
      },
      {
        path: "manage-push-events",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.myPushEvents",
          roles: ["Provider", "Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ManagePushEventsComponent,
            data: {
              breadcrumb: "",
              roles: ["Provider", "Admin"],
            },
          },
          {
            path: "event-type-details",
            component: EventTypeComponent,
            data: {
              breadcrumb: "navigation.eventTypeDetails",
              roles: ["Provider", "Admin"],
            },
          },
          {
            path: "event-type-details/:id",
            component: EventTypeComponent,
            data: {
              breadcrumb: "navigation.eventTypeDetails",
              roles: ["Provider", "Admin"],
            },
          },
        ],
      },
      {
        path: "resource-access-requests",
        canActivate: [MsalGuard, RoleGuard],
        data: {
          breadcrumb: "navigation.manageResourceRequests",
          roles: ["Provider", "Admin"],
        },
        children: [
          {
            path: "",
            pathMatch: "full",
            component: ResourceAccessRequestsComponent,
            data: {
              breadcrumb: "",
              roles: ["Provider", "Admin"],
            },
          },
          {
            path: "resource-request-card",
            component: ResourceRequestsCardComponent,
            data: {
              breadcrumb: "navigation.manageResourceRequests",
              roles: ["Provider", "Admin"],
            },
          },
          {
            path: "provider-rars/:id",
            component: ProviderRarsComponent,
            data: {
              breadcrumb: "navigation.manageResourceRequests",
              roles: ["Provider", "Admin"],
            },
          },
        ],
      },
    ]),
  ],
  exports: [
    ResourceRequestsCardComponent,
    ProviderRarsComponent,
    ResourceAccessRequestsComponent,
  ],
})
export class DataProviderModule {}
