import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { DarStatus } from '../models/dar-status-summary';
import { FarmStatus } from '../models/farm-status-summary';
import { AccessRequestStatus } from '../../shared/models/access-request-status';
import { TextFilterDef } from '../../shared/base-remote-datatable/models/text-filter-def';
import { SelectFilterDef } from '../../shared/base-remote-datatable/models/select-filter-def';
import { SelectOption } from '../../shared/base-remote-datatable/models/select-option';
import { ColumnDef } from '../../shared/base-remote-datatable/models/column-def';
import { InitialSort } from '../../shared/base-remote-datatable/models/initial-sort';
import { ConsumerFarmResourceAccessService } from '../../core/services/consume-farm-resource-access.service';

@Component({
  selector: 'app-view-farm-resource-access',
  templateUrl: './view-farm-resource-access.component.html',
  styleUrls: ['./view-farm-resource-access.component.css']
})
export class ViewFarmResourceAccessComponent implements OnInit {
  @ViewChild('farmNumberCell', { static: true }) farmNumberCell: TemplateRef<any>;
  @ViewChild('resourceNameCell', { static: true }) resourceNameCell: TemplateRef<any>;
  @ViewChild('resourceStatusCell', { static: true }) resourceStatusCell: TemplateRef<any>;
  @ViewChild('darStatusCell', { static: true }) darStatusCell: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort('resourceName');

  constructor(public farmResourceAccessService: ConsumerFarmResourceAccessService) {
  }

  ngOnInit() {
    this.columns = [{
      name: 'farmNumber',
      translationKey: 'models.farmResourceAccess.farmResourceAccessTable.farmNumber',
      cellTemplate: this.farmNumberCell,
      filter: new TextFilterDef('farmNumberFilter'),
      displayed: true,
      sort: 'farmNumber'
    },
    {
      name: 'resourceName',
      translationKey: 'models.farmResourceAccess.farmResourceAccessTable.resourceName',
      cellTemplate: this.resourceNameCell,
      filter: new TextFilterDef('resourceNameFilter'),
      displayed: true,
      sort: 'resourceName'
    },
    {
      name: 'resourceStatus',
      translationKey: 'models.farmResourceAccess.farmResourceAccessTable.resourceStatus',
      cellTemplate: this.resourceStatusCell,
      filter: new SelectFilterDef('resourceStatusFilter',
        Object.keys(AccessRequestStatus)
          .filter(value => isNaN(Number(value)) === false)
          .map(x => new SelectOption(x, `models.accessRequest.statusses.${AccessRequestStatus[x]}`))),
      displayed: true,
      sort: 'resourceStatus'
    },
    {
      name: 'darStatus',
      translationKey: 'models.farmResourceAccess.farmResourceAccessTable.darStatus',
      cellTemplate: this.darStatusCell,
      filter: new SelectFilterDef('darStatusFilter',
        Object.keys(DarStatus)
          .filter(value => isNaN(Number(value)) === false)
          .map(x => new SelectOption(x, `models.farmResourceAccess.statuses.${DarStatus[x]}`))),
      displayed: true,
      sort: 'darStatus'
    }];
  }
}
