import { APP_INITIALIZER, Inject, Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { BehaviorSubject, Observable, ReplaySubject, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UserRole } from "../shared/models/datahubUser";
import { UserContextDTO } from "../shared/models/userContextDTO";
import { ContextService } from "./services/context.service";
import { AccountInfo, Configuration } from "@azure/msal-browser";

@Injectable()
export class UserAuthService {
  private subscription = new Subscription();
  private allContextsSubject = new ReplaySubject<Array<UserContextDTO>>(1);
  private currentContextSubject = new ReplaySubject<UserContextDTO>(1);
  private currentContext: Observable<UserContextDTO>;
  private context: UserContextDTO;
  private userId = "";
  private isLoggedInSubject: BehaviorSubject<boolean>;
  private isLoadingRolesSubject: BehaviorSubject<boolean>;

  constructor(
    @Inject(APP_INITIALIZER) config: Configuration,
    public msalService: MsalService,
    private contextService: ContextService
  ) {
    config[0]();
    this.isLoggedInSubject = new BehaviorSubject<boolean>(this.isLoggedIn());
    this.isLoadingRolesSubject = new BehaviorSubject<boolean>(false);
  }

  ngOnDestroy() {
    if (this.subscription) this.subscription.unsubscribe();
  }

  public isTokenExpired(): boolean {
    //todo msal??
    //const token = this. .getCachedTokenInternal("https://ilvodatahubidentity.onmicrosoft.com/localapi/user.call", this.msalService.instance.getAllAccounts[0], '');
    //return token && token.expiresOn < new Date(Date.now());
    return false;
  }

  public isLoggedIn(): boolean {
    return this.msalService.instance.getAllAccounts().length > 0;
  }

  public notifyIsLoggedInChanged(): Observable<boolean> {
    return this.isLoggedInSubject.asObservable();
  }

  public notifyIsLoadingRolesChanged(): Observable<boolean> {
    return this.isLoadingRolesSubject.asObservable();
  }

  public isLoggedInAndHasRole(): boolean {
    return this.isLoggedIn() && this.hasRole();
  }

  public initContexts() {
    if (this.isLoggedIn()) {
      this.subscription.add(
        this.getUserContexts().subscribe((r) => this.getInitialContext())
      );
    }
  }

  public getInitialContext() {
    this.subscription.add(this.getCurrent().subscribe());
  }

  private getUserContexts(): Observable<Array<UserContextDTO>> {
    return this.contextService.getUserContexts().pipe(
      map((contexts) => {
        var mappedContexts = contexts.map((context) =>
          Object.assign(new UserContextDTO(), context)
        );
        this.allContextsSubject.next(mappedContexts);
        return mappedContexts;
      })
    );
  }

  public setUserContext(context: UserContextDTO) {
    this.context = context;
    context.userId = this.userId;
    this.currentContextSubject.next(context);
    return this.contextService.setUserContext(context);
  }

  private getCurrent(): Observable<UserContextDTO> {
    if (!this.currentContext) {
      this.isLoadingRolesSubject.next(true);
      this.currentContext = this.contextService.getContext().pipe(
        map((context) => {
          const contextDTO = Object.assign(new UserContextDTO(), context);
          if (!this.context || this.context.id !== contextDTO.id) {
            this.context = context;
            this.userId = contextDTO.userId;
            this.currentContextSubject.next(contextDTO);
          }
          this.isLoadingRolesSubject.next(false);
          return contextDTO;
        })
      );
    }
    return this.currentContext;
  }

  public setActiveAccount(account: AccountInfo){
    this.msalService.instance.setActiveAccount(account);
    this.currentContext = null;
    this.subscription.add(
      this.getUserContexts().subscribe((r) => 
        this.getInitialContext())
    );
    this.isLoggedInSubject.next(true);
  }

  public cleanupAfterLogout(){
    this.isLoggedInSubject.next(false);
    this.cleanupCookie();

    sessionStorage.removeItem("useflow");
    localStorage.clear();
    localStorage.setItem("LoggedOut", "True");
  }

  public cleanupCookie(){
    let d: Date = new Date();
      d.setTime(d.getTime() + 0);
      let expires: string = `expires=${d.toUTCString()}`;
      let cpath: string = "path" ? `; path=""` : "";
      
    document.cookie = `${"ai_user"}=${""}; ${expires}${cpath}`;
    document.cookie = `${"ai_session"}=${""}; ${expires}${cpath}`;
  }

  public relog() {
    const session = JSON.parse(sessionStorage.getItem("appConfig"));
    const postLogoutRedirectUri = session["msal"]["base"]["auth"] ? session["msal"]["base"]["auth"]["postLogoutRedirectUri"] : "/";
    this.msalService.logoutRedirect({ postLogoutRedirectUri: `${postLogoutRedirectUri}?login=true` });
  }

  public notifyCurrentContextChanged(): Observable<UserContextDTO> {
    return this.currentContextSubject.asObservable();
  }

  public getAllContexts(): Observable<Array<UserContextDTO>> {
    return this.allContextsSubject.asObservable();
  }

  public get ContextIdentifier() {
    if (this.context) return this.context.id;
    return undefined;
  }

  private hasRole(): boolean {
    return this.context && Boolean(this.context.roleName);
  }

  public isAdmin(): boolean {
    return this.hasRole() && this.context.roleName === UserRole.Admin;
  }

  public isConsumer(): boolean {
    return this.hasRole() && this.context.roleName === UserRole.Consumer;
  }

  public isProvider(): boolean {
    return this.hasRole() && this.context.roleName === UserRole.Provider;
  }

  public isPartner(): boolean {
    return this.isConsumer() || this.isProvider();
  }

  public isFarmer(): boolean {
    return this.hasRole() && this.context.roleName === UserRole.Farmer;
  }

  public isUnknown(): boolean {
    return this.hasRole() && this.context.roleName === UserRole.Unknown;
  }
}
