<div class="header-part">
  <h1>{{ consumer?.consumerName }}</h1>
  <img
    class="app-logo"
    *ngIf="hasApplicationLogoUrl(); else dclogo"
    src="{{ this.baseurl + consumer.applicationLogoUrl }}"
  />
  <ng-template #dclogo>
    <img class="app-logo" src="/assets/images/blauwMiddel 1.svg" />
  </ng-template>
</div>

<form [formGroup]="rarStatusForm">
  <div class="description-part">
    <h2>
      {{ consumer?.companyName }}
    </h2>
    <p>{{ consumer?.consumerPurpose }}</p>
    <ng-template [ngIf]="consumer?.applicationUrl">
      <div class="more-info">
        <!--p class="bold">{{ "info.moreInfo" | translate }}</p-->
        <a [href]="consumer.applicationUrl" target="_blank">
          {{
            "dataAccessRequestPage.card.goToApplicationConsumer"
              | translate : consumer
          }}</a
        >
      </div>
    </ng-template>

    <div class="text-row">
      <p class="bold">{{ "info.allFarms" | translate }} :</p>
      <p>
        {{ consumer?.allFarms | BooleanTranslation | translate }}
      </p>
    </div>

    <ng-template [ngIf]="!this.informativeFlow && expirationDate">
      <div class="text-row">
        <p class="bold">{{ "info.expirationDate" | translate }} :</p>
        <p>
          {{ expirationDate | date : "yyyy-MM-dd hh:mm" }}
        </p>
      </div>
    </ng-template>
  </div>

  <div class="datatable-part" *ngIf="requestsPending.controls.length > 0">
    <p class="lowercase">{{ "info.dataAccessRequests" | translate }}</p>
    <div class="button-group">
      <button (click)="approveAllPending()" class="green" [disabled]="arePending() ? null : true">
        {{ "dataAccessRequestPage.card.approveAllPending" | translate }}
      </button>
    </div>
    <div class="table">
      <div class="table-header">
        <div class="header-part" *ngFor="let column of columns">
          <p class="bold">{{ column.name | translate }}</p>
        </div>
      </div>

      <div class="table-body" formArrayName="requestsPending">
        <div
          class="table-row"
          *ngFor="let rq of requestsPending.controls; index as pendingIndex"
          [formGroupName]="pendingIndex"
        >
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="
                apiDescriptionTranslationTmpl;
                context: { row: rq }
              "
            ></ng-container>
          </div>
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="
                externalDocumentationUrlTmpl;
                context: { row: rq }
              "
            ></ng-container>
          </div>
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="resourceTranslationTmpl; context: { row: rq }"
            ></ng-container>
          </div>
          <div class="table-row-part">
            <p *ngIf="rq.get('creationDate').value; else noDate">
              <ng-container
                *ngTemplateOutlet="
                  dateTmpl;
                  context: { value: rq.get('creationDate').value }
                "
              ></ng-container>
            </p>
          </div>
          <div class="table-row-part">
            <p *ngIf="rq.get('reviewDate').value; else noDate">
              <ng-container
                *ngTemplateOutlet="dateTmpl; context: { value: rq.get('reviewDate').value }"
              ></ng-container>
            </p>
          </div>
          <div class="table-row-part">
            <app-approve-reject-selector
              formControlName="status"
            ></app-approve-reject-selector>
            <div *ngIf="rq.get('expirationDate').value">
              <span class="small gray-color">
                {{ "models.farmDataAccessRequest.accessUntil" | translate }}
                {{ rq.get('expirationDate').value | date : "yyyy-MM-dd hh:mm" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <p class="total">
        {{ "grid.total" | translate }}:
        {{ requestsPendingArray.length }}
      </p>
    </div>
  </div>

  <div class="datatable-part" *ngIf="requestsHandled.controls.length > 0">
    <p>{{ "info.dataAccessRequestsTreated" | translate }}</p>
    <div class="table">
      <div class="table-header">
        <div class="header-part" *ngFor="let column of columns">
          <p class="bold">{{ column.name | translate }}</p>
        </div>
      </div>

      <div class="table-body" formArrayName="requestsHandled">
        <div
          class="table-row"
          *ngFor="let rq of requestsHandled.controls;index as handledIndex"
          [formGroupName]="handledIndex"
        >
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="
                apiDescriptionTranslationTmpl;
                context: { row: rq }
              "
            ></ng-container>
          </div>
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="
                externalDocumentationUrlTmpl;
                context: { row: rq }
              "
            ></ng-container>
          </div>
          <div class="table-row-part">
            <ng-container
              *ngTemplateOutlet="resourceTranslationTmpl; context: { row: rq }"
            ></ng-container>
          </div>
          <div class="table-row-part">
            <p *ngIf="rq.get('creationDate').value; else noDate">
              <ng-container
                *ngTemplateOutlet="
                  dateTmpl;
                  context: { value: rq.get('creationDate').value }
                "
              ></ng-container>
            </p>
          </div>
          <div class="table-row-part">
            <p *ngIf="rq.get('reviewDate').value; else noDate">
              <ng-container
                *ngTemplateOutlet="dateTmpl; context: { value: rq.get('reviewDate').value }"
              ></ng-container>
            </p>
          </div>
          <div class="table-row-part">
            <app-approve-reject-selector
              formControlName="status"
            ></app-approve-reject-selector>
            <div *ngIf="rq.get('expirationDate').value">
              <span class="small gray-color">
                {{ "models.farmDataAccessRequest.accessUntil" | translate }}
                {{ rq.get('expirationDate').value | date : "yyyy-MM-dd hh:mm" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <p class="total">
        {{ "grid.total" | translate }}:
        {{ requestsHandledArray.length }}
      </p>
    </div>
  </div>
</form>
  <button class="green" (click)="goBack()">
    {{ "actions.back" | translate }}
  </button>

<ng-template #noDate>
  <p>/</p>
</ng-template>

<ng-template #apiDescriptionTranslationTmpl let-row="row">
  <app-translatable-string-view
    [translation]="row.get('translations').value"
    property="apiDescription"
  ></app-translatable-string-view>
</ng-template>

<ng-template #externalDocumentationUrlTmpl let-row="row">
  <a
  *ngIf="row.get('externalDocumentationUrl').value" 
  [href]="row.get('externalDocumentationUrl').value" 
  target="_blank"
  >{{
    ("info.moreInfo" | translate).split(":")[0]
  }}</a>
</ng-template>

<ng-template #resourceTranslationTmpl let-row="row">
  <app-translatable-string-view
    [translation]="row.get('translations').value"
    property="resource"
  ></app-translatable-string-view>
</ng-template>
<ng-template #dateTmpl let-value="value">
  {{ value | date : "dd-MM-yyyy" }}
</ng-template>
