import {
  Component,
  OnInit,
  ViewChild,
  Input,
  TemplateRef,
  OnDestroy,
  SimpleChanges,
  OnChanges,
} from "@angular/core";
import { DatatableComponent } from "@swimlane/ngx-datatable";
import { Filter } from "./models/filter";
import { Subscription } from "rxjs";
// TODO UPDATE import { MatDateRangePicker } from "@angular/material/datepicker";
import { Language } from "../models/language";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { exportDataToCsv } from "../models/export-to-csv";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";

declare global {
  interface Navigator {
    msSaveBlob: (blob: any, defaultName?: string) => boolean;
  }
}

@Component({
  selector: "app-base-datatable",
  templateUrl: "./base-datatable.component.html",
  styleUrls: ["./base-datatable.component.scss"],
})
export class BaseDatatableComponent implements OnInit, OnChanges, OnDestroy {
  @Input() lastColumnIcon: boolean = false;
  @Input() rows: Array<any>;
  @Input() showExport: boolean = true;
  @Input() columns: Array<any>;
  @Input() headerHeight: number;
  @Input() footerHeight: number;
  @Input() limit: number;
  private _exportable = true;
  @Input() get exportable() {
    return this._exportable;
  }

  set exportable(value) {
    this._exportable = value != null ? value : true;
  }

  @ViewChild(DatatableComponent, { static: true })
  private table: DatatableComponent;
  @ViewChild("hdrTextTpl", { static: true }) hdrTextTpl: TemplateRef<any>;
  @ViewChild("hdrTranslationTpl", { static: true })
  hdrTranslationTpl: TemplateRef<any>;
  @ViewChild("hdrSelectTpl", { static: true }) hdrSelectTpl: TemplateRef<any>;
  @ViewChild("hdrDateTpl", { static: true }) hdrDateTpl: TemplateRef<any>;

  shownColumns: Array<any>;
  filteredRows: Array<any>;
  private currentLanguage: Language;
  private currentFilters = new Array<Filter>();
  private rowSubscription: Subscription;

  constructor(private translate: TranslateService, private dialog: MatDialog) {
    this.translate.onLangChange.subscribe((l: LangChangeEvent) => {
      this.currentLanguage = Language[l.lang];
      this.applyAllFilters();
    });
    this.translate.currentLang
      ? (this.currentLanguage = Language[this.translate.currentLang])
      : (this.currentLanguage = Language.nl);
  }

  ngOnInit() {
    this.shownColumns = this.columns.filter((c) => !c.hidden);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.rows) this.setRows(changes.rows.currentValue);
  }

  ngOnDestroy() {
    if (this.rowSubscription) {
      this.rowSubscription.unsubscribe();
    }
  }

  private setRows(rows: Array<any>) {
    this.rows = rows;
    this.filteredRows = this.rows;
    this.currentFilters.forEach((filter) => {
      this.filteredRows = this.filteredRows.filter(filter.filterFunc);
    });
  }

  textfilterData(event, prop) {
    const func = function (d, v, language) {
      if (v === undefined) {
        return true;
      } else if (d[prop]) {
        return d[prop].toLowerCase().indexOf(v) !== -1;
      } else {
        return v === "";
      }
    };

    const val = event.target.value.toLowerCase();
    this.applyNewFilters(new Filter(prop, val, func));
  }

  translationfilterData(event, prop) {
    const func = function (d, v, language) {
      const t = d.translations.find((x) => x.language === language)[prop];
      return t.toLowerCase().indexOf(v) !== -1 || v === undefined;
    };

    const val = event.target.value.toLowerCase();
    this.applyNewFilters(new Filter(prop, val, func));
  }

  selectfilterData(event, prop) {
    const func = function (d, v, language) {
      return d[prop] === v || v === undefined;
    };

    const val = event.value;
    this.applyNewFilters(new Filter(prop, val, func));
  }

  datefilterData(event, prop) {
    const func = function (d, v, language) {
      const r = new Date(d[prop]);
      return !v || (r > v.begin && r < v.end);
    };

    //var val = event.value as SatDatepickerRangeValue<Date>;
    //this.applyNewFilters(new Filter(prop, val, func));
  }

  private applyNewFilters(filter: Filter) {
    this.updateFilters(filter);
    this.applyAllFilters();
  }

  private updateFilters(filter: Filter) {
    const index = this.currentFilters.findIndex((e) => e.prop === filter.prop);
    if (filter.value === undefined) {
      if (this.currentFilters.length > 0) {
        if (index !== -1) {
          this.currentFilters.splice(index, 1);
        }
      }
    } else if (index === -1) {
      this.currentFilters.push(filter);
    } else {
      this.currentFilters[index].value = filter.value;
    }
  }

  private applyAllFilters() {
    let rows = this.rows;
    this.currentFilters.forEach((filter) => {
      if ((!filter.value && filter.value !== 0) || !rows || !rows.length) return;
      rows = rows.filter((f) =>
        filter.filterFunc(f, filter.value, this.currentLanguage)
      );
    });

    this.filteredRows = rows;
    this.table.offset = 0;
  }

  // Changed version of https://stackoverflow.com/questions/51487689/angular-5-how-to-export-data-to-csv-file
  async exportToCsv(): Promise<any> {
    if (!this.exportable) {
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: {
        title: "actions.warning",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      // User clicked no
      if (!result) return;

      const date = new Date();
      const filename = `${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}.csv`;
      const keys = this.shownColumns
        .filter(
          (c) =>
            c.name != undefined &&
            !(c.name as string).includes("actions.actions")
        )
        .map((p) => p.prop);
      let csvContent = exportDataToCsv(
        keys,
        this.filteredRows,
        this.currentLanguage
      );
      const blob = new Blob([csvContent], {
        type: "text/csv;charset=utf-8;",
      });
      if (navigator.msSaveBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        const link = document.createElement("a");
        if (link.download !== undefined) {
          const url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", filename);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    });
  }
}
