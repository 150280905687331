import { FarmStatus } from './farm-status-summary';
import { AccessRequestStatus } from '../../shared/models/access-request-status';

export class DarStatusSummary {
    partnerName: string;
    farmNumber: string;
    kboNumber: string;
    resourceName: string;
    farmStatus: FarmStatus;
    resourceStatus: AccessRequestStatus;
    DarStatus: DarStatus;
}


export enum DarStatus {
    Pending = 0,
    Approved = 1,
    Rejected = 2,
    NotApplicable = 3,
    NoMapping = 4,
    NoData = 5
}