<h1>{{ "info.partnerDetails" | translate }}</h1>
<div class="details-header">
  <h2>{{ "partners.details.title" | translate }}</h2>
  <button [class]="readonly ? 'green' : 'rose'" (click)="toggleEdit()">
    <i class="fa fa-user-edit"></i>
    {{ (readonly ? "actions.editUser" : "actions.cancel") | translate }}
  </button>
</div>
<form
  [formGroup]="partnerForm"
  (ngSubmit)="upsertPartner(partnerForm.getRawValue())"
>
  <div class="form-part">
    <label>{{ "models.datahubPartner.name" | translate }}</label>
    <app-translatable-string
      formControlName="name"
      placeholder="Name"
      required="true"
    ></app-translatable-string>
  </div>

  <div class="form-part">
    <label>{{ "models.datahubPartner.purpose" | translate }}</label>
    <app-translatable-string
      formControlName="purpose"
      placeholder="Purpose"
      required="true"
    ></app-translatable-string>
  </div>

  <div class="form-part">
    <label for="applicationUrl">{{
      "models.datahubPartner.appUrl" | translate
    }}</label>
    <input
      autocomplete="off"
      formControlName="applicationUrl"
      name="applicationUrl"
      id="applicationUrl"
      type="text"
    />
    <!-- [value]="partner.applicationUrl" -->
  </div>

  <div class="form-part">
    <label for="companyName">{{
      "models.datahubPartner.companyName" | translate
    }}</label>
    <input
      autocomplete="off"
      formControlName="companyName"
      name="companyName"
      id="companyName"
      type="text"
    />
    <!-- [value]="partner.companyName" -->
  </div>

  <div class="form-part">
    <label for="applicationLogoUrl">{{
      "models.datahubPartner.logoUrl" | translate
    }}</label>
    <input
      autocomplete="off"
      formControlName="applicationLogoUrl"
      name="applicationLogoUrl"
      id="applicationLogoUrl"
      type="text"
    />
  </div>

  <!-- <mat-form-field>
            <mat-label>{{ 'models.datahubPartner.role' | translate }}</mat-label>
            <mat-select formControlName="role">
                <mat-option *ngFor="let role of roles" [value]="role.value">
                {{role.name | translate}}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <div *ngIf="isConsumer && !isNewPartner">
            <mat-checkbox formControlName="informativeFlow" disabled="true">{{ 'models.datahubPartner.informativeFlow' | translate}}</mat-checkbox>            
        </div>

        <div *ngIf="isNewPartner">
            <mat-checkbox formControlName="informativeFlow">{{ 'models.datahubPartner.informativeFlow' | translate}}</mat-checkbox>            
        </div> -->

  <div class="form-part">
    <label for="role">{{ "models.datahubPartner.role" | translate }}</label>
    <select formControlName="role" (change)="onRoleChange($event)">
      <option *ngFor="let role of roles" [value]="role.value">
        {{ role.name | translate }}
      </option>
    </select>
  </div>
  <div class="form-part" *ngIf="isConsumer && isNewPartner">
    <label for="check-informative-flow" class="checkbox">
      {{ "models.datahubPartner.informativeFlow" | translate }}
      <input
        type="checkbox"
        name="check-informative-flow"
        id="check-informative-flow"
        formControlName="informativeFlow"
      />
      <svg
        class="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#1d1d1b"
          rx="3"
        />
        <path
          class="tick"
          stroke="#067C4A"
          fill="none"
          stroke-linecap="round"
          stroke-width="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </label>
  </div>

  <div
    class="form-part"
    *ngIf="isNewPartner && selectedRole === 'roles.Consumer'"
  >
    <label for="check-informative-flow-2" class="checkbox">
      {{ "models.datahubPartner.informativeFlow" | translate }}
      <input
        type="checkbox"
        name="check-informative-flow-2"
        id="check-informative-flow-2"
        formControlName="informativeFlow"
      />
      <svg
        class="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#1d1d1b"
          rx="3"
        />
        <path
          class="tick"
          stroke="#067C4A"
          fill="none"
          stroke-linecap="round"
          stroke-width="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </label>
  </div>

  <app-certificate
    [formGroup]="partnerForm"
    [currentCertificateThumbprint]="thumbprint"
    (password)="handlePassword($event)"
  ></app-certificate>
  <div *ngIf="!isNewPartner" class="cert-buttons">
    <button
      type="button"
      [disabled]="!thumbprint"
      (click)="downloadCertificate()"
      class="green"
    >
      <i class="fas fa-download"></i>
      <span>
        {{ "actions.downloadCertificate" | translate }}
      </span>
    </button>
    <button type="button" (click)="generateCertificate()" class="rose">
      <i class="fas fa-plus"></i>
      <span>
        {{ "actions.generateCertificate" | translate }}
      </span>
    </button>
  </div>
<div class="user-management">
  <app-manage-users
    [showExport]="false"
    [users]="users"
    (remove)="removeUser($event)"
  ></app-manage-users>
  <app-manage-invites
    [showExport]="false"
    [invitees]="invitees"
    [users]="users"
    (invite)="inviteUser($event)"
  ></app-manage-invites>
  <button class="green" type="submit" [disabled]="!partnerForm.valid">
    {{ "actions.save" | translate }}
  </button>
</div>
</form>
